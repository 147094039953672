import { AjaxError } from 'rxjs/ajax';

import {
    Announcement,
    DOWNLOAD_ANNOUNCEMENT_FILE,
    DOWNLOAD_ANNOUNCEMENT_FILE_FAIL,
    DOWNLOAD_ANNOUNCEMENT_FILE_SUCCESS,
    DownloadAnnouncementFileAction,
    DownloadAnnouncementFileFailAction,
    DownloadAnnouncementFileSuccessAction,
    INIT_ANNOUNCEMENTS,
    INIT_ANNOUNCEMENTS_FAIL,
    INIT_ANNOUNCEMENTS_SUCCESS,
    InitAnnouncementsAction,
    InitAnnouncementsFailAction,
    InitAnnouncementsSuccessAction,
    READ_ANNOUNCEMENTS,
    READ_ANNOUNCEMENTS_FAIL,
    READ_ANNOUNCEMENTS_SUCCESS,
    ReadAnnouncementsAction,
    ReadAnnouncementsFailAction,
    ReadAnnouncementsSuccessAction,
} from './types';

export const downloadAnnouncementFile = (id: number, mediaId: string): DownloadAnnouncementFileAction => ({
    type: DOWNLOAD_ANNOUNCEMENT_FILE,
    payload: {
        id,
        mediaId,
    },
});

export const downloadAnnouncementFileSuccess = (): DownloadAnnouncementFileSuccessAction => ({
    type: DOWNLOAD_ANNOUNCEMENT_FILE_SUCCESS,
});

export const downloadAnnouncementFileFail = (
    error: AjaxError,
    id: number,
    mediaId: string,
): DownloadAnnouncementFileFailAction => ({
    type: DOWNLOAD_ANNOUNCEMENT_FILE_FAIL,
    payload: {
        error,
        id,
        mediaId,
    },
});

export const initAnnouncements = (): InitAnnouncementsAction => ({
    type: INIT_ANNOUNCEMENTS,
});

export const initAnnouncementsSuccess = (announcements: Announcement[]): InitAnnouncementsSuccessAction => ({
    type: INIT_ANNOUNCEMENTS_SUCCESS,
    payload: {
        announcements,
    },
});

export const initAnnouncementsFail = (error: AjaxError): InitAnnouncementsFailAction => ({
    type: INIT_ANNOUNCEMENTS_FAIL,
    error,
});

export const readAnnouncements = (): ReadAnnouncementsAction => ({
    type: READ_ANNOUNCEMENTS,
});

export const readAnnouncementsSuccess = (): ReadAnnouncementsSuccessAction => ({
    type: READ_ANNOUNCEMENTS_SUCCESS,
});

export const readAnnouncementsFail = (error: AjaxError): ReadAnnouncementsFailAction => ({
    type: READ_ANNOUNCEMENTS_FAIL,
    error,
});
