import _ from 'lodash';
import { User } from 'oidc-client';
import { Observable, from } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map, mergeMap } from 'rxjs/operators';

import userManager from './userManager';

const defaultUrlRoot = window.config.customerportalApiUrl;

const userToAuthToken = (user: User | null) =>
    user ? { Authorization: `${user.token_type} ${user.access_token}` } : {};

const defaultHeaders = {
    'Content-type': 'Application/json',
};

const get = (
    url: string,
    queryParameters: {
        [k: string]: string | number | boolean | { [k: string]: string | string[] | number | boolean };
    } = {},
    headers: { [k: string]: string | undefined } = {},
): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) => {
            // Construct query parameter string
            const params = Object.keys(queryParameters);
            let parameterString = '';
            for (let i = 0; i < params.length; i++) {
                if (parameterString === '') {
                    parameterString += '?';
                } else {
                    parameterString += '&';
                }
                // Without this, non-objects get unnecessary "" around them
                if (typeof queryParameters[params[i]] === 'string') {
                    // Typescript wont recognize this as string without <string>
                    parameterString += `${params[i]}=${encodeURIComponent(queryParameters[params[i]] as string)}`;
                } else {
                    parameterString += `${params[i]}=${encodeURIComponent(JSON.stringify(queryParameters[params[i]]))}`;
                }
            }

            return ajax({
                method: 'GET',
                url: `${defaultUrlRoot}${url}${parameterString}`,
                headers: _.assign({}, defaultHeaders, authHeaders, headers),
            });
        }),
    );

const blob = (url: string, headers: { [k: string]: string } = {}): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax({
                method: 'GET',
                url: `${defaultUrlRoot}${url}`,
                headers: _.assign({}, authHeaders, headers),
                responseType: 'arraybuffer',
            }),
        ),
    );

export const customerportalApi = {
    get,
    blob,
};
