import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';

// State

export interface OldOrdersState {
    orders: Order[];
    contentRange: number;
    pageSize: number;
    loading: boolean;
    pdfNotFound: number[];
}

// Action types

export const FETCH_OLD_ORDERS = 'old-orders:FETCH_OLD_ORDERS';
export const FETCH_OLD_ORDERS_SUCCESS = 'old-orders:FETCH_OLD_ORDERS_SUCCESS';
export const FETCH_OLD_ORDERS_FAIL = 'old-orders:FETCH_OLD_ORDERS_FAIL';
export const DOWNLOAD_ORDER_PDF = 'old-orders:DOWNLOAD_ORDER_PDF';
export const DOWNLOAD_ORDER_PDF_SUCCESS = 'old-orders:DOWNLOAD_ORDER_PDF_SUCCESS';
export const DOWNLOAD_ORDER_PDF_FAIL = 'old-orders:DOWNLOAD_ORDER_PDF_FAIL';

// Actions

export interface FetchOldOrdersAction extends Action {
    type: typeof FETCH_OLD_ORDERS;
    payload: {
        page: number;
        filter: Record<string, string>;
    };
}

export interface FetchOldOrdersSuccessAction extends Action {
    type: typeof FETCH_OLD_ORDERS_SUCCESS;
    payload: {
        orders: Order[];
        contentRange: number;
    };
}

export interface FetchOldOrdersFailAction extends Action {
    type: typeof FETCH_OLD_ORDERS_FAIL;
    error: AjaxError;
}

export interface DownloadOrderPdfAction extends Action {
    type: typeof DOWNLOAD_ORDER_PDF;
    payload: {
        id: number;
    };
}

export interface DownloadOrderPdfSuccessAction extends Action {
    type: typeof DOWNLOAD_ORDER_PDF_SUCCESS;
}

export interface DownloadOrderPdfFailAction extends Action {
    type: typeof DOWNLOAD_ORDER_PDF_FAIL;
    payload: {
        error: AjaxError;
        id: number;
    };
}

export type OldOrdersActionTypes =
    | FetchOldOrdersAction
    | FetchOldOrdersSuccessAction
    | FetchOldOrdersFailAction
    | DownloadOrderPdfAction
    | DownloadOrderPdfSuccessAction
    | DownloadOrderPdfFailAction;
