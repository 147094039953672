import { useEffect, useState } from 'react';

import { Order, OrderGroup } from '../../lib/order/types';
import { Category } from '../../redux/categories/types';
import Table from './Table';
import { PriceTotals, Status } from './types';
import { combineStatuses, formatPrice, getEmptyPriceTotals } from './utils';

interface Props {
    category: Category;
    deleteRow: (_: number) => void;
    dirtyPriceTime: string;
    enablePriceEdit: boolean;
    group: OrderGroup;
    hideError: () => void;
    order: Order;
    returnedRow: number;
    setStatus: (_: Status) => void;
    showError: (_: string) => void;
    hidePrice: boolean;
    strictMode: number;
}

const Group = ({
    category,
    deleteRow,
    dirtyPriceTime,
    enablePriceEdit,
    group,
    hideError,
    order,
    returnedRow,
    setStatus,
    showError,
    hidePrice,
    strictMode,
}: Props) => {
    const [normalTotal, setNormalTotal] = useState<PriceTotals>(getEmptyPriceTotals());
    const [accessoryTotal, setAccessoryTotal] = useState<PriceTotals>(getEmptyPriceTotals());
    const [normalRowStatus, setNormalRowStatus] = useState<Status>('EMPTY');
    const [accessoryRowStatus, setAccessoryRowStatus] = useState<Status>('EMPTY');

    useEffect(() => {
        setStatus(combineStatuses(new Set([normalRowStatus, accessoryRowStatus])));
    }, [normalRowStatus, accessoryRowStatus]);

    return (
        <div className='sub-order__wrapper'>
            <h2>Tuotteet</h2>
            <Table
                calculatePrice={category.calculatePrice}
                deleteRow={deleteRow}
                dirtyPriceTime={dirtyPriceTime}
                enablePriceEdit={enablePriceEdit}
                fields={category.fields}
                groupId={group.id}
                hideError={hideError}
                identifier={`${category.id}`}
                orderId={order.id}
                returnedRow={returnedRow}
                rows={group.rows}
                setStatus={setNormalRowStatus}
                setTotals={setNormalTotal}
                showError={showError}
                showSquares={category.showSquares}
                hidePrice={hidePrice}
                strictMode={strictMode}
                type='NORMAL'
            />

            {/* ACCESSORIES */}
            {category.accessoryFields.length > 0 && (
                <>
                    <h2>Lisätarvikkeet</h2>
                    <Table
                        calculatePrice={category.calculatePrice}
                        deleteRow={deleteRow}
                        dirtyPriceTime={dirtyPriceTime}
                        enablePriceEdit={enablePriceEdit}
                        fields={category.accessoryFields}
                        groupId={group.id}
                        hideError={hideError}
                        identifier={`${category.id}-accessory`}
                        orderId={order.id}
                        returnedRow={returnedRow}
                        rows={group.accessoryRows}
                        setStatus={setAccessoryRowStatus}
                        setTotals={setAccessoryTotal}
                        showError={showError}
                        showSquares={false}
                        hidePrice={hidePrice}
                        strictMode={strictMode}
                        type='ACCESSORY'
                    />
                </>
            )}

            {/* SUBORDER SUMMARY*/}
            {category.accessoryFields.length > 0 && category.calculatePrice && (
                <div className='sub_order__full-summary-wrapper'>
                    <span className='sub_order__full-summary'>
                        Merkin OVH yhteensä:{' '}
                        <span className='sub_order__full-summary-price'>
                            {formatPrice(normalTotal.price + accessoryTotal.price)}
                        </span>
                    </span>
                    {!hidePrice && (
                        <span className='sub_order__full-summary'>
                            Merkin netto yhteensä:{' '}
                            <span className='sub_order__full-summary-price'>
                                {formatPrice(normalTotal.finalPrice + accessoryTotal.finalPrice)}
                            </span>
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default Group;
