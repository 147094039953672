import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import NotificationIcon from '../../assets/images/icons/notifications.svg';
import { RootState } from '../../redux';
import { api } from '../../utils/api';
import userManager from '../../utils/userManager';

const Navbar = () => {
    const location = useLocation();

    const config = useSelector((state: RootState) => state.config.config);
    const oidcUser = useSelector((state: RootState) => state.oidc.user);
    const unreadAnnouncements = useSelector((state: RootState) => state.announcements.unreadAnnouncements);
    const savedOrders = useSelector((state: RootState) => state.savedOrders.savedOrders);

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        setShowMenu(false);
    }, [location.pathname]);

    const toggleMenu = () => setShowMenu(!showMenu);

    const handleLogout = () => {
        userManager.getUser().then((user) => {
            void userManager.removeUser();
            void userManager.signoutRedirect(
                user
                    ? {
                          id_token_hint: user?.id_token,
                      }
                    : {
                          extraQueryParams: {
                              client_id: window.config.clientId,
                          },
                      },
            );
        });
    };

    return (
        <div className='navbar'>
            <div className='navbar__content main-wrapper'>
                {config.logo?.image ? (
                    <Link to='/'>
                        <img className='navbar__logo' src={api.mediumThumbnail(config.logo.image)} alt='logo' />
                    </Link>
                ) : (
                    <div className='navbar__logo' />
                )}
                <div className='navbar__menu'>
                    <nav className='navbar__nav'>
                        <a href={window.config.customerportalUrl} className='navbar__nav-link'>
                            Asiakasportaali
                        </a>
                        <Link to='/' className='navbar__nav-link'>
                            Etusivu
                        </Link>
                        <Link to='/tallennetut-tilaukset' className='navbar__nav-link'>
                            Tallennetut tilaukset
                            {savedOrders && <div className='red-dot' />}
                        </Link>
                        <Link to='/tehdyt-tilaukset' className='navbar__nav-link'>
                            Tehdyt tilaukset
                        </Link>
                    </nav>
                    <div className='navbar__personal'>
                        <Link to='/tiedotteet' className='btn btn-icon navbar__notifications-btn'>
                            <img src={NotificationIcon} alt='Tiedotteet' />
                            {unreadAnnouncements && <div className='red-dot' />}
                        </Link>
                        <button className={classNames('navbar__menu-btn', { open: showMenu })} onClick={toggleMenu}>
                            {oidcUser?.profile?.company ? `${oidcUser.profile.company} - ` : ''}
                            {oidcUser?.profile?.name}
                        </button>
                        {showMenu && (
                            <ul className='navbar__menu-list'>
                                <li className='navbar__menu-item'>
                                    <button className='btn navbar__user-logout' onClick={handleLogout}>
                                        Kirjaudu ulos
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
