import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';

// State

export interface SavedOrdersState {
    savedOrders: boolean;
    orders: Order[];
    pageSize: number;
    contentRange: number;
    loading: boolean;
    showSaveSuccess: boolean;
    showSaveFail: boolean;
    deleteId: number | null;
    deletePage: number | null;
    deleteFail: boolean;
    deleting: boolean;
    filtersBeforeDeleting: Record<string, string> | null;
}

// Action types

export const CHECK_SAVED_ORDERS = 'saved-orders:CHECK_SAVED_ORDERS';
export const CHECK_SAVED_ORDERS_SUCCESS = 'saved-orders:CHECK_SAVED_ORDERS_SUCCESS';
export const CHECK_SAVED_ORDERS_FAIL = 'saved-orders:CHECK_SAVED_ORDERS_FAIL';
export const FETCH_SAVED_ORDERS = 'saved-orders:FETCH_SAVED_ORDERS';
export const FETCH_SAVED_ORDERS_SUCCESS = 'saved-orders:FETCH_SAVED_ORDERS_SUCCESS';
export const FETCH_SAVED_ORDERS_FAIL = 'saved-orders:FETCH_SAVED_ORDERS_FAIL';
export const IMPORT_SAVED_ORDER = 'saved-orders:IMPORT_SAVED_ORDER';
export const IMPORT_SAVED_ORDER_FAIL = 'saved-orders:IMPORT_SAVED_ORDER_FAIL';
export const PROC_DELETE_SAVED_ORDER = 'saved-orders:PROC_DELETE_SAVED_ORDER';
export const DELETE_SAVED_ORDER = 'saved-orders:DELETE_SAVED_ORDER';
export const DELETE_SAVED_ORDER_SUCCESS = 'saved-orders:DELETE_SAVED_ORDER_SUCCESS';
export const DELETE_SAVED_ORDER_FAIL = 'saved-orders:DELETE_SAVED_ORDER_FAIL';
export const DELETE_SAVED_ORDER_CLEAR = 'saved-orders:DELETE_SAVED_ORDER_CLEAR';
export const CONTINUE_SAVED_ORDER = 'saved-orders:CONTINUE_SAVED_ORDER';
export const CONTINUE_SAVED_ORDER_HELPER = 'saved-orders:CONTINUE_SAVED_ORDER_HELPER';

// Actions

export interface CheckSavedOrdersAction extends Action {
    type: typeof CHECK_SAVED_ORDERS;
}

export interface CheckSavedOrderSuccessAction extends Action {
    type: typeof CHECK_SAVED_ORDERS_SUCCESS;
    payload: {
        savedOrders: boolean;
    };
}

export interface CheckSavedOrdersFailAction extends Action {
    type: typeof CHECK_SAVED_ORDERS_FAIL;
    error: AjaxError;
}

export interface FetchSavedOrdersAction extends Action {
    type: typeof FETCH_SAVED_ORDERS;
    payload: {
        page: number;
        filter: Record<string, string>;
    };
}

export interface FetchSavedOrdersSuccessAction extends Action {
    type: typeof FETCH_SAVED_ORDERS_SUCCESS;
    payload: {
        orders: Order[];
        contentRange: number;
    };
}

export interface FetchSavedOrdersFailAction extends Action {
    type: typeof FETCH_SAVED_ORDERS_FAIL;
    error: AjaxError;
}

export interface ImportSavedOrderAction extends Action {
    type: typeof IMPORT_SAVED_ORDER;
    payload: {
        id: number;
    };
}

export interface ImportSavedOrderFailAction extends Action {
    type: typeof IMPORT_SAVED_ORDER_FAIL;
    error: AjaxError;
}

export interface ProcDeleteSavedOrderAction extends Action {
    type: typeof PROC_DELETE_SAVED_ORDER;
    payload: {
        id: number;
        page: number;
        filters: Record<string, string>;
    };
}

export interface DeleteSavedOrderAction extends Action {
    type: typeof DELETE_SAVED_ORDER;
}

export interface DeleteSavedOrderSuccessAction extends Action {
    type: typeof DELETE_SAVED_ORDER_SUCCESS;
}

export interface DeleteSavedOrderFailAction extends Action {
    type: typeof DELETE_SAVED_ORDER_FAIL;
    error: AjaxError;
}

export interface DeleteSavedOrderClearAction extends Action {
    type: typeof DELETE_SAVED_ORDER_CLEAR;
}

export interface ContinueSavedOrderAction extends Action {
    type: typeof CONTINUE_SAVED_ORDER;
    payload: {
        order: Order;
    };
}

export interface ContinueSavedOrderHelperAction extends Action {
    type: typeof CONTINUE_SAVED_ORDER_HELPER;
    payload: {
        order: Order;
    };
}

export type SavedOrdersActionTypes =
    | CheckSavedOrdersAction
    | CheckSavedOrderSuccessAction
    | CheckSavedOrdersFailAction
    | FetchSavedOrdersAction
    | FetchSavedOrdersSuccessAction
    | FetchSavedOrdersFailAction
    | ImportSavedOrderAction
    | ProcDeleteSavedOrderAction
    | DeleteSavedOrderAction
    | DeleteSavedOrderSuccessAction
    | DeleteSavedOrderFailAction
    | DeleteSavedOrderClearAction
    | ContinueSavedOrderAction
    | ContinueSavedOrderHelperAction;
