import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    CellEditingStoppedEvent,
    CellKeyDownEvent,
    ColDef,
    ColumnResizedEvent,
    ColumnState,
    GetRowIdParams,
    GridApi,
    GridReadyEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactForRef } from 'ag-grid-react/dist/types/src/agGridReact';
import { debounce } from 'lodash';

import AddIcon from '../../assets/images/icons/add.svg';
import ErrorIcon from '../../assets/images/icons/error.svg';
import {
    createOrderAccessoryRow,
    createOrderRow,
    refreshOrderAccessoryRowPrice,
    refreshOrderRowPrice,
    setOrderAccessoryRowPrice,
    setOrderRowPrice,
    updateOrderAccessoryRow,
    updateOrderRow,
} from '../../lib/order';
import { OKPriceWrapper, OrderRow } from '../../lib/order/types';
import { RootState } from '../../redux';
import { Field } from '../../redux/categories/types';
import { validateOrderFields } from '../../utils/order';
import useTaskQueue from '../../utils/useTaskQueue';
import LoadingIcon from '../loading-icon';
import RowAction from './RowAction';
import { GridRow, LocalOrderRow, PriceTotals, Status } from './types';
import {
    columnSizeMapping,
    combineStatuses,
    defaultColDef,
    formatPrice,
    formatSquares,
    generateColDefs,
    getEmptyPriceTotals,
    getOrderRowStatus,
    getOrderRowStatusByPrice,
    gridRowToOrderRow,
    orderRowToGridRow,
} from './utils';

interface Props {
    calculatePrice: boolean;
    deleteRow: (_: number) => void;
    dirtyPriceTime: string;
    enablePriceEdit: boolean;
    fields: Field[];
    groupId: number;
    hideError: () => void;
    identifier: string;
    orderId: number;
    returnedRow: number;
    rows: OrderRow[];
    setStatus: (_: Status) => void;
    setTotals: (_: PriceTotals) => void;
    showError: (_: string) => void;
    showSquares: boolean;
    hidePrice: boolean;
    strictMode: number;
    type: 'NORMAL' | 'ACCESSORY';
}

const Table = ({
    calculatePrice,
    deleteRow,
    dirtyPriceTime,
    enablePriceEdit,
    fields,
    groupId,
    hideError,
    identifier,
    orderId,
    returnedRow,
    rows,
    setStatus,
    setTotals,
    showError,
    showSquares,
    hidePrice,
    strictMode,
    type,
}: Props) => {
    const debounceMap = new Map();
    const { addTask } = useTaskQueue();

    const oidcUser = useSelector((state: RootState) => state.oidc.user);

    const gridRef = useRef<AgGridReactForRef>(null);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [loadingAddRow, setLoadingAddRow] = useState(false);
    const [rowStatus, setRowStatus] = useState<Record<number, Status>>(
        rows.reduce(
            (rowStatus, orderRow) => ({ ...rowStatus, [orderRow.id]: getOrderRowStatus(orderRow, fields) }),
            {},
        ),
    );

    const [colDefs, setColDefs] = useState<ColDef[]>(
        generateColDefs(fields, calculatePrice, enablePriceEdit, showSquares, hidePrice, strictMode > 0),
    );
    const [localRows, setLocalRows] = useState<LocalOrderRow[]>(rows);
    const [rowData, setRowData] = useState<GridRow[]>(
        rows.map((row) => orderRowToGridRow(row, calculatePrice, calculatePrice)),
    );
    const [localTotals, setLocalTotals] = useState(getEmptyPriceTotals());

    useEffect(() => {
        setColDefs(generateColDefs(fields, calculatePrice, enablePriceEdit, showSquares, hidePrice, strictMode > 0));
    }, [strictMode, hidePrice]);

    useEffect(() => {
        const totals = localRows
            .filter(({ deleted }) => deleted === undefined)
            .map(({ price }) => price)
            .filter<OKPriceWrapper>((price): price is OKPriceWrapper => price.status === 'OK')
            .reduce(
                (total, { count, totalFinalPrice, totalPrice, totalPriceSquares }) => ({
                    ...total,
                    area: total.area + totalPriceSquares,
                    count: total.count + count,
                    price: total.price + totalPrice,
                    finalPrice: total.finalPrice + totalFinalPrice,
                }),
                {
                    count: 0,
                    area: 0,
                    price: 0,
                    finalPrice: 0,
                },
            );
        setLocalTotals(totals);
        setTotals(totals);
    }, [localRows]);

    useEffect(() => {
        const askCloseConfirmation = (e: BeforeUnloadEvent) => {
            if (
                Object.keys(rowStatus).length === 0 ||
                ['LOADING', 'ERROR'].indexOf(combineStatuses(new Set(Object.values(rowStatus)))) === -1
            )
                return;

            e.preventDefault();
            // Modern browsers don't show this text
            e.returnValue = 'Sinulla on muutoksia, jotka eivät ole tallentuneet. Haluatko varmasti sulkea sivun?';
            return e.returnValue;
        };

        window.addEventListener('beforeunload', askCloseConfirmation);
        return () => window.removeEventListener('beforeunload', askCloseConfirmation);
    }, [rowStatus]);

    useEffect(() => {
        setStatus(combineStatuses(new Set(Object.values(rowStatus))));
    }, [rowStatus]);

    useEffect(() => {
        localRows
            .filter(({ deleted }) => deleted === undefined)
            .filter(({ price: { calculated } }) => new Date(calculated) < new Date(dirtyPriceTime))
            .forEach(({ id }) => handleRefreshOrderRowPrice(id));
    }, [dirtyPriceTime]);

    useEffect(() => {
        const deletedRow = localRows.find(({ deleted }) => deleted === returnedRow);
        if (!deletedRow) return;
        handleAddRow(deletedRow, returnedRow);
    }, [returnedRow]);

    const migrateColumnStateColIds = (states: ColumnState[]): ColumnState[] => states.map((state) => ({ ...state }));

    const handleGridReady = ({ api }: GridReadyEvent) => {
        setGridApi(api);

        const state = migrateColumnStateColIds(
            (JSON.parse(localStorage.getItem(`gridColumnState-${identifier}`) || 'null') || []) as ColumnState[],
        );

        fields
            .filter(({ field }) => state.find(({ colId }) => colId === `field-${field.id}`) === undefined)
            .forEach(({ field }) => {
                state.push({
                    colId: `field-${field.id}`,
                    flex: columnSizeMapping[field.width],
                });
            });

        api.applyColumnState({ state });

        api.addEventListener('cellKeyDown', (event: CellKeyDownEvent) => {
            if (event.rowIndex === null || event.api.getEditingCells().length === 0) return;
            switch ((event.event as KeyboardEvent)?.key) {
                case 'ArrowUp':
                    if (event.rowIndex > 0) {
                        event.api.setFocusedCell(event.rowIndex - 1, event.column);
                        event.api.startEditingCell({ rowIndex: event.rowIndex - 1, colKey: event.column });
                    }
                    event.event?.preventDefault();
                    return;
                case 'ArrowDown':
                    if (event.rowIndex < event.api.getDisplayedRowCount() - 1) {
                        event.api.setFocusedCell(event.rowIndex + 1, event.column);
                        event.api.startEditingCell({ rowIndex: event.rowIndex + 1, colKey: event.column });
                    }
                    event.event?.preventDefault();
                    return;
                case 'ArrowRight':
                    event.api.tabToNextCell((event.event as KeyboardEvent | null) ?? undefined);
                    return;
                case 'ArrowLeft':
                    event.api.tabToPreviousCell((event.event as KeyboardEvent | null) ?? undefined);
                    return;
            }
        });
    };

    const handleColumnResized = ({ finished, source }: ColumnResizedEvent) => {
        if (!finished || source !== 'uiColumnResized' || gridRef.current == null) return;
        const newState = gridRef.current.api.getColumnState().map(({ colId, width, flex }) => ({
            colId,
            width,
            flex,
        }));
        localStorage.setItem(`gridColumnState-${identifier}`, JSON.stringify(newState));
    };

    const getRowId = useCallback((params: GetRowIdParams) => params.data.id, []);

    const handleAddRow = useCallback(
        (orderRow?: OrderRow, deleted?: number) => {
            hideError();
            setLoadingAddRow(true);
            (type === 'NORMAL' ? createOrderRow : createOrderAccessoryRow)(
                oidcUser?.access_token ?? '',
                orderId,
                groupId,
                orderRow,
            )
                .then((orderRow) => {
                    setLocalRows((rows) => [
                        ...(deleted ? rows.filter((row) => row.deleted !== deleted) : rows),
                        orderRow,
                    ]);
                    setRowData((rows) => [...rows, orderRowToGridRow(orderRow, calculatePrice, calculatePrice)]);
                })
                .catch((err) => {
                    console.error(err);
                    showError(
                        (deleted ? 'Rivin kumoaminen epäonnistui.' : 'Uuden rivin lisääminen epäonnistui.') +
                            ' Voit kokeilla päivittää sivun ja ladata tilauksen tallennetuista tilauksta. Mikäli ' +
                            'ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                    );
                })
                .finally(() => setLoadingAddRow(false));
        },
        [oidcUser?.access_token, type, orderId, groupId],
    );

    const handleUpdateOrderRow = useCallback(
        async (row: OrderRow) => {
            hideError();
            setRowStatus((rowStatus) => ({ ...rowStatus, [row.id]: 'LOADING' }));
            return (type === 'NORMAL' ? updateOrderRow : updateOrderAccessoryRow)(
                oidcUser?.access_token ?? '',
                orderId,
                groupId,
                row.id,
                row,
            )
                .then((orderRow) => {
                    setLocalRows((localRows) =>
                        localRows.map((localRow) => (localRow.id === orderRow.id ? orderRow : localRow)),
                    );
                    if (!calculatePrice) {
                        setRowStatus((rowStatus) => ({
                            ...rowStatus,
                            [row.id]: !validateOrderFields(orderRow, fields) ? 'INVALID_CELL' : 'OK',
                        }));
                        return;
                    }

                    const { id, price, productCode } = orderRow;

                    const rowNode = gridApi?.getRowNode(String(id));
                    if (!rowNode) return;

                    rowNode.setDataValue('productCode', productCode);
                    rowNode.setDataValue('unitPrice', price);
                    rowNode.setDataValue('totalPrice', price);
                    rowNode.setDataValue('totalFinalPrice', price);

                    if (showSquares) rowNode.setDataValue('squares', price);

                    setRowStatus((rowStatus) => ({ ...rowStatus, [row.id]: getOrderRowStatus(orderRow, fields) }));
                })
                .catch((err) => {
                    console.error(err);
                    showError(
                        'Rivin tietojen tallentaminen epäonnistui. Voit kokeilla päivittää sivun ja ladata tilauksen ' +
                            'tallennetuista tilauksta, mutta huomioithan, että juuri lisäämäsi tiedot eivät ' +
                            'todennäköisesti ole tallella. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                    );
                    setRowStatus((rowStatus) => ({ ...rowStatus, [row.id]: 'ERROR' }));
                });
        },
        [oidcUser?.access_token, type, orderId, groupId, gridApi, calculatePrice, showSquares],
    );

    const handleUpdateOrderRowPrice = useCallback(
        async (rowId: number, price: number) => {
            hideError();
            const oldRowStatus = rowStatus[rowId];
            setRowStatus((rowStatus) => ({ ...rowStatus, [rowId]: 'LOADING' }));
            return (type === 'NORMAL' ? setOrderRowPrice : setOrderAccessoryRowPrice)(
                oidcUser?.access_token ?? '',
                orderId,
                groupId,
                rowId,
                price,
            )
                .then((price) => {
                    setLocalRows((localRows) =>
                        localRows.map((localRow) => (localRow.id === rowId ? { ...localRow, price } : localRow)),
                    );

                    const rowNode = gridApi?.getRowNode(String(rowId));
                    if (!rowNode) return;

                    rowNode.setDataValue('unitPrice', price);
                    rowNode.setDataValue('totalPrice', price);
                    rowNode.setDataValue('totalFinalPrice', price);

                    if (showSquares) rowNode.setDataValue('squares', price);

                    setRowStatus((rowStatus) => ({
                        ...rowStatus,
                        [rowId]: getOrderRowStatusByPrice(price, oldRowStatus),
                    }));
                })
                .catch((err) => {
                    console.error(err);
                    showError(
                        'Rivin hinnan tallentaminen epäonnistui. Voit kokeilla päivittää sivun ja ladata tilauksen ' +
                            'tallennetuista tilauksta, mutta huomioithan, että juuri lisäämäsi tiedot eivät ' +
                            'todennäköisesti ole tallella. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                    );
                    setRowStatus((rowStatus) => ({ ...rowStatus, [rowId]: 'ERROR' }));
                });
        },
        [oidcUser?.access_token, type, orderId, groupId, gridApi, showSquares],
    );

    const handleRefreshOrderRowPrice = useCallback(
        (rowId: number) => {
            hideError();
            const oldRowStatus = rowStatus[rowId];
            setRowStatus((rowStatus) => ({ ...rowStatus, [rowId]: 'LOADING' }));
            (type === 'NORMAL' ? refreshOrderRowPrice : refreshOrderAccessoryRowPrice)(
                oidcUser?.access_token ?? '',
                orderId,
                groupId,
                rowId,
            )
                .then((price) => {
                    setLocalRows((localRows) =>
                        localRows.map((localRow) => (localRow.id === rowId ? { ...localRow, price } : localRow)),
                    );

                    const rowNode = gridApi?.getRowNode(String(rowId));
                    if (!rowNode) return;

                    rowNode.setDataValue('unitPrice', price);
                    rowNode.setDataValue('totalPrice', price);
                    rowNode.setDataValue('totalFinalPrice', price);

                    if (showSquares) rowNode.setDataValue('squares', price);

                    setRowStatus((rowStatus) => ({
                        ...rowStatus,
                        [rowId]: getOrderRowStatusByPrice(price, oldRowStatus),
                    }));
                })
                .catch((err) => {
                    console.error(err);
                    showError(
                        'Rivin hinnan päivittäminen epäonnistui. Voit kokeilla päivittää sivun ja ladata tilauksen ' +
                            'tallennetuista tilauksta, mutta huomioithan, että juuri lisäämäsi tiedot eivät ' +
                            'todennäköisesti ole tallella. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                    );
                    setRowStatus((rowStatus) => ({ ...rowStatus, [rowId]: 'ERROR' }));
                });
        },
        [oidcUser?.access_token, type, orderId, groupId, gridApi, showSquares],
    );

    const handleDeleteRow = (rowId: number) => {
        const deleted = Date.now();
        setLocalRows((rows) => rows.map((orderRow) => (orderRow.id !== rowId ? orderRow : { ...orderRow, deleted })));
        setRowData((rows) => rows.filter(({ id }) => id !== rowId));
        setRowStatus(({ [rowId]: _, ...rowStatus }) => rowStatus);
        deleteRow(deleted);
    };

    const handleNewRow = (orderRow: OrderRow) => {
        setLocalRows((rows) => [
            ...rows.slice(0, orderRow.orderNumber),
            orderRow,
            ...rows.slice(orderRow.orderNumber ?? rows.length),
        ]);
        setRowData((rows) => [
            ...rows.slice(0, orderRow.orderNumber),
            orderRowToGridRow(orderRow, calculatePrice, calculatePrice),
            ...rows.slice(orderRow.orderNumber ?? rows.length),
        ]);
    };

    const handleCellEditingStopped = (e: CellEditingStoppedEvent) => {
        const isUnitPriceField = e.colDef.field === 'unitPrice';
        const identifier = isUnitPriceField ? `unitPrice-${e.data.id}` : e.data.id;

        let debounced = debounceMap.get(identifier);

        if (!debounced) {
            debounced = debounce(
                () =>
                    addTask(() =>
                        isUnitPriceField
                            ? handleUpdateOrderRowPrice(e.data.id, e.newValue.unitPrice)
                            : handleUpdateOrderRow(gridRowToOrderRow(e.data, fields)),
                    ),
                1000,
            );
            debounceMap.set(identifier, debounced);
        }

        debounced();
    };

    return (
        <>
            {rowData.length > 0 && (
                <>
                    <div className='sub-order__grid ag-theme-alpine'>
                        {/* PRE GRID */}
                        <div className='sub-order__pre'>
                            {localRows
                                .filter(({ deleted }) => deleted === undefined)
                                .map((row) =>
                                    row.fields.some((f) => f.deprecated && f.value) ? (
                                        <div key={row.id} className='tooltip'>
                                            <div className='sub-order__pre-row'>
                                                <img src={ErrorIcon} alt='Virhe icon' />
                                            </div>
                                            <div className='tooltip__wrapper tooltip__wrapper--left'>
                                                <div className='tooltip__text'>
                                                    Poistuneet kentät
                                                    <br />
                                                    <br />
                                                    {row.fields
                                                        .filter(({ deprecated }) => deprecated)
                                                        .map((f) => (
                                                            <>
                                                                <span>
                                                                    {f.name}:
                                                                    {f['@type'] === 'Select'
                                                                        ? f.value.name || ''
                                                                        : (f.value as string | number | null)}
                                                                </span>
                                                                <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={row.id} className='sub-order__pre-row'></div>
                                    ),
                                )}
                        </div>
                        {/* GRID */}
                        <div className='sub-order__rows'>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={colDefs}
                                rowData={rowData}
                                suppressNoRowsOverlay
                                // This is here to prevent scroll bar from flickering at the start
                                suppressHorizontalScroll={!rowData.length}
                                domLayout='autoHeight'
                                defaultColDef={defaultColDef}
                                singleClickEdit
                                stopEditingWhenCellsLoseFocus
                                suppressRowClickSelection
                                headerHeight={50}
                                tooltipShowDelay={0}
                                onGridReady={handleGridReady}
                                onCellEditingStopped={handleCellEditingStopped}
                                onColumnResized={handleColumnResized}
                                getRowId={getRowId}
                                popupParent={document.querySelector('body')}
                                reactiveCustomComponents={true}
                            />
                        </div>
                        {/* AFTER GRID */}
                        <div className='sub-order__additions'>
                            {localRows
                                .filter(({ deleted }) => deleted === undefined)
                                .map((row) => (
                                    <div key={row.id} className='sub-order__after-row'>
                                        <RowAction
                                            addRow={handleNewRow}
                                            deleteRow={handleDeleteRow}
                                            groupId={groupId}
                                            hideError={hideError}
                                            orderId={orderId}
                                            rowId={row.id}
                                            showError={showError}
                                            type={type}
                                        />
                                        <div className='sub-order__loading-status'>
                                            {rowStatus[row.id] === 'LOADING' ? (
                                                <LoadingIcon />
                                            ) : rowStatus[row.id] === 'ERROR' ? (
                                                <div key={row.id} className='tooltip'>
                                                    <img src={ErrorIcon} alt='Virhe icon' />
                                                    <div className='tooltip__wrapper tooltip__wrapper--right'>
                                                        <div className='tooltip__text'>
                                                            Tämän rivin tallentaminen on epäonnistunut
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {/* SUMMARY */}
                    <div className='sub-order__grid sub-order__summary'>
                        {calculatePrice && (
                            <>
                                <div>
                                    <div className='sub-order__summary-counts-title'>Yhteensä:</div>
                                </div>
                                <div className='sub-order__rows'>
                                    <div className='sub-order__summary-count'>{localTotals.count} kpl</div>
                                </div>
                            </>
                        )}
                        {calculatePrice && (
                            <div className='sub-order__summary_cells'>
                                {showSquares && (
                                    <div className='sub-order__summary-cell'>{formatSquares(localTotals.area)}</div>
                                )}
                                <div className='sub-order__summary-cell' />
                                <div className='sub-order__summary-cell'>{formatPrice(localTotals.price)}</div>
                                {!hidePrice && (
                                    <div className='sub-order__summary-cell'>{formatPrice(localTotals.finalPrice)}</div>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}

            {/* GRID ACTIONS */}
            <div className='sub-order__actions btn-row'>
                <button className='btn btn-primary btn-w-icon' onClick={() => handleAddRow()} disabled={loadingAddRow}>
                    {loadingAddRow ? <LoadingIcon /> : <img src={AddIcon} alt='Lisää tyhjä rivi' />}
                    Lisää tyhjä rivi
                </button>
            </div>
        </>
    );
};

export default Table;
