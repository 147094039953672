import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import { initCustomerFail, initCustomerSuccess } from './actions';
import { CustomerActionTypes, CustomerState, INIT_CUSTOMER, INIT_CUSTOMER_SUCCESS, InitCustomerAction } from './types';

const initialState: CustomerState = {
    customer: undefined,
};

const customerChange = (state = initialState, action: CustomerActionTypes): CustomerState => {
    switch (action.type) {
        case INIT_CUSTOMER_SUCCESS:
            return _.assign({}, state, { customer: action.payload.customer });
        default:
            return state;
    }
};

export const initCustomerEpic = (action$: Observable<InitCustomerAction>): Observable<CustomerActionTypes> =>
    action$.pipe(
        ofType(INIT_CUSTOMER),
        mergeMap(() =>
            api.get('/customer/me').pipe(
                map((res) => initCustomerSuccess(res.response)),
                catchError((error) => of(initCustomerFail(error))),
            ),
        ),
    );

export default customerChange;
