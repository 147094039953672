import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';

import LoadingIcon from '../../components/loading-icon';

const OrderList = (props) => {
    const [page, setPage] = useState(1);

    const { list, contentRange, pageSize, loading, fetchOrders, listItemAction: ListItemAction, filters } = props;

    const handlePageChange = (page) => {
        setPage(page);
        fetchOrders(page - 1);
    };

    return (
        <div>
            <ul className='order-info__list'>
                {loading && list.length === 0 && <LoadingIcon />}
                {list.length === 0 && !loading && 'Ei hakutuloksia'}
                {(!loading || list.length > 0) &&
                    list.map((order) => (
                        <li className='order-info' key={order.id}>
                            <span className='order-info__order-id'>{order.orderNumber}</span>
                            <span className='order-info__order-time'>{new Date(order.orderTime).toLocaleString()}</span>
                            <span className='order-info__category'>[{order.category.name}]</span>
                            {order.reference}
                            <ListItemAction order={order} page={page} filters={filters} />
                        </li>
                    ))}
                {loading && list.length > 0 && (
                    <div className='order-info__list-loading'>
                        <LoadingIcon />
                    </div>
                )}
            </ul>
            <div className='pagination-wrapper'>
                <Pagination
                    activePage={page}
                    totalItemsCount={contentRange}
                    itemsCountPerPage={pageSize}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default OrderList;
