import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import { initUserFail, initUserSuccess } from './actions';
import { INIT_USER, INIT_USER_SUCCESS, InitUserAction, UserChangeActionTypes, UserState } from './types';

const initialState: UserState = {};

const user = (state = initialState, action: UserChangeActionTypes): UserState => {
    switch (action.type) {
        case INIT_USER_SUCCESS:
            return _.assign({}, state, { user: action.payload.user });
        default:
            return state;
    }
};

export const initUserEpic = (action$: Observable<InitUserAction>): Observable<UserChangeActionTypes> =>
    action$.pipe(
        ofType(INIT_USER),
        mergeMap(() =>
            api.get('/user/me').pipe(
                map((res) => initUserSuccess(res.response)),
                catchError((error) => of(initUserFail(error))),
            ),
        ),
    );

export default user;
