import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { Outlet, Route, Routes } from 'react-router-dom';

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';
import { OidcProvider, loadUser } from 'redux-oidc';

import './assets/styles/styles.scss';
import AuthWrapper from './components/auth-wrapper';
import ExcelNotification from './components/excel-notification';
import Navbar from './components/navbar';
import rootReducer, { rootEpic } from './redux';
import { initAnnouncements } from './redux/announcements/actions';
import { initConfig } from './redux/config/actions';
import { initCustomer } from './redux/customer/actions';
import { checkSavedOrders } from './redux/saved-orders/actions';
import { initUser } from './redux/user/actions';
import userManager from './utils/userManager';
import Announcements from './views/announcements';
import Category from './views/category';
import FixOrder from './views/fix-order';
import Home from './views/home';
import OldOrders from './views/old-orders';
import SavedOrders from './views/saved-orders';

const Layout = () => {
    // Setup Redux
    const epicMiddleware = createEpicMiddleware();
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));
    epicMiddleware.run(rootEpic);

    const init = () => {
        store.dispatch(initConfig());
        store.dispatch(initUser());
        store.dispatch(initCustomer());
        store.dispatch(initAnnouncements());
        store.dispatch(checkSavedOrders());
    };

    // Load potentially existing user data
    loadUser(store, userManager);

    return (
        <Provider store={store}>
            {/* @ts-expect-error Official support for React 18 Typescript not yet available */}
            <OidcProvider store={store} userManager={userManager}>
                <AuthWrapper onSuccess={init}>
                    <div className='u-full-height'>
                        <Navbar />
                        <div className='page-wrap'>
                            <Outlet />
                        </div>
                        <ExcelNotification />
                    </div>
                </AuthWrapper>
            </OidcProvider>
        </Provider>
    );
};

const App: FC = () => (
    <Routes>
        <Route element={<Layout />}>
            <Route path='*' element={<Home />} />
            <Route path='/kategoria/:id' element={<Category />} />
            <Route path='/tehdyt-tilaukset' element={<OldOrders />} />
            <Route path='/tallennetut-tilaukset' element={<SavedOrders />} />
            <Route path='/fix-order/:id' element={<FixOrder />} />
            <Route path='/tiedotteet' element={<Announcements />} />
        </Route>
    </Routes>
);

export default App;
