import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

export interface AnnouncementFile {
    id: string;
    file: string;
    name: string;
}

export interface Announcement {
    id: number;
    title: string;
    message: string;
    releaseDate: string;
    editDate: string;
    files: AnnouncementFile[];
}

// State

export interface AnnouncementsState {
    announcements: Announcement[];
    announcementsRead: string | null;
    unreadAnnouncements: boolean;
}

// Action types

export const DOWNLOAD_ANNOUNCEMENT_FILE = 'announcements:DOWNLOAD_ANNOUNCEMENT_FILE';
export const DOWNLOAD_ANNOUNCEMENT_FILE_SUCCESS = 'announcements:DOWNLOAD_ANNOUNCEMENT_FILE_SUCCESS';
export const DOWNLOAD_ANNOUNCEMENT_FILE_FAIL = 'announcements:DOWNLOAD_ANNOUNCEMENT_FILE_FAIL';
export const INIT_ANNOUNCEMENTS = 'announcements:INIT_ANNOUNCEMENTS';
export const INIT_ANNOUNCEMENTS_SUCCESS = 'announcements:INIT_ANNOUNCEMENTS_SUCCESS';
export const INIT_ANNOUNCEMENTS_FAIL = 'announcements:INIT_ANNOUNCEMENTS_FAIL';
export const READ_ANNOUNCEMENTS = 'announcements:READ_ANNOUNCEMENTS';
export const READ_ANNOUNCEMENTS_SUCCESS = 'announcements:READ_ANNOUNCEMENTS_SUCCESS';
export const READ_ANNOUNCEMENTS_FAIL = 'announcements:READ_ANNOUNCEMENTS_FAIL';

// Actions

export interface DownloadAnnouncementFileAction extends Action {
    type: typeof DOWNLOAD_ANNOUNCEMENT_FILE;
    payload: {
        id: number;
        mediaId: string;
    };
}

export interface DownloadAnnouncementFileSuccessAction extends Action {
    type: typeof DOWNLOAD_ANNOUNCEMENT_FILE_SUCCESS;
}

export interface DownloadAnnouncementFileFailAction extends Action {
    type: typeof DOWNLOAD_ANNOUNCEMENT_FILE_FAIL;
    payload: {
        error: AjaxError;
        id: number;
        mediaId: string;
    };
}

export interface InitAnnouncementsAction extends Action {
    type: typeof INIT_ANNOUNCEMENTS;
}

export interface InitAnnouncementsSuccessAction extends Action {
    type: typeof INIT_ANNOUNCEMENTS_SUCCESS;
    payload: {
        announcements: Announcement[];
    };
}

export interface InitAnnouncementsFailAction extends Action {
    type: typeof INIT_ANNOUNCEMENTS_FAIL;
    error: AjaxError;
}

export interface ReadAnnouncementsAction extends Action {
    type: typeof READ_ANNOUNCEMENTS;
}

export interface ReadAnnouncementsSuccessAction extends Action {
    type: typeof READ_ANNOUNCEMENTS_SUCCESS;
}

export interface ReadAnnouncementsFailAction extends Action {
    type: typeof READ_ANNOUNCEMENTS_FAIL;
    error: AjaxError;
}

export type AnnouncementsActionTypes =
    | DownloadAnnouncementFileAction
    | DownloadAnnouncementFileSuccessAction
    | DownloadAnnouncementFileFailAction
    | InitAnnouncementsAction
    | InitAnnouncementsSuccessAction
    | InitAnnouncementsFailAction
    | ReadAnnouncementsAction
    | ReadAnnouncementsSuccessAction
    | ReadAnnouncementsFailAction;
