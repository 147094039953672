import { FC } from 'react';
import { Link } from 'react-router-dom';

import { api } from '../../utils/api';

interface props {
    id: number;
    name: string;
    img: string;
}

const CategoryLink: FC<props> = ({ id, name, img }) => {
    return (
        <li className='category-link__wrapper'>
            <Link className='category-link' to={`kategoria/${id}`}>
                {img && <img className='category-link__img' src={api.image(img)} alt={name} />}
                <div className='category-link__title'>{name}</div>
            </Link>
        </li>
    );
};

export default CategoryLink;
