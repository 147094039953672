// https://gist.github.com/tomhicks/95d455186fadda2f7d99e6163aa9d360
import { useCallback, useEffect, useState } from 'react';

const useTaskQueue = (): {
    addTask: (task: Task) => void;
} => {
    const [queue, setQueue] = useState<{
        isProcessing: boolean;
        tasks: Array<Task>;
    }>({ isProcessing: false, tasks: [] });

    useEffect(() => {
        if (queue.tasks.length === 0) return;
        if (queue.isProcessing) return;

        const task = queue.tasks[0];
        setQueue((prev) => ({
            isProcessing: true,
            tasks: prev.tasks.slice(1),
        }));

        Promise.resolve(task()).finally(() => {
            setQueue((prev) => ({
                isProcessing: false,
                tasks: prev.tasks,
            }));
        });
    }, [queue]);

    return {
        addTask: useCallback((task) => {
            setQueue((prev) => ({
                isProcessing: prev.isProcessing,
                tasks: [...prev.tasks, task],
            }));
        }, []),
    };
};

type Task = () => Promise<void> | void;

export default useTaskQueue;
