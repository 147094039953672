import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

// State

export interface ConfigState {
    config: Record<string, string> & {
        logo?: {
            image?: string;
        };
    };
}

// Action types

export const INIT_CONFIG = 'config:INIT_CONFIG';
export const INIT_CONFIG_SUCCESS = 'config:INIT_CONFIG_SUCCESS';
export const INIT_CONFIG_FAIL = 'config:INIT_CONFIG_FAIL';

// Actions

export interface InitConfigAction extends Action {
    type: typeof INIT_CONFIG;
}

export interface InitConfigSuccessAction extends Action {
    type: typeof INIT_CONFIG_SUCCESS;
    payload: {
        res: Record<string, string>;
    };
}

export interface InitConfigFailAction extends Action {
    type: typeof INIT_CONFIG_FAIL;
    error: AjaxError;
}

export type ConfigActionTypes = InitConfigAction | InitConfigSuccessAction | InitConfigFailAction;
