import api from '../../utils/apiWithoutRedux';
import { NewOrder, Order, OrderGroup, OrderRow, PriceWrapper, UpdateOrder } from './types';

export const createOrder = (accessToken: string, order: NewOrder) => api.post<Order>(`/order`, accessToken, order);

export const getOrder = (accessToken: string, id: number) => api.get<Order>(`/order/${id}`, accessToken);

export const updateOrder = (accessToken: string, id: number, order: UpdateOrder) =>
    api.put<Order>(`/order/${id}`, accessToken, order);

export const deleteOrder = (accessToken: string, id: number) => api.delete<Order>(`/order/${id}`, accessToken);

export const sendOrder = (accessToken: string, id: number) => api.patch(`/order/${id}/send`, accessToken);

export const createOrderGroup = (accessToken: string, orderId: number) =>
    api.post<OrderGroup>(`/order/${orderId}/group`, accessToken);

export const deleteOrderGroup = (accessToken: string, orderId: number) =>
    api.delete<OrderGroup>(`/order/${orderId}/group`, accessToken);

export const createOrderRow = (accessToken: string, orderId: number, orderGroupId: number, orderRow?: OrderRow) =>
    api.post<OrderRow>(`/order/${orderId}/group/${orderGroupId}/row`, accessToken, orderRow);

export const updateOrderRow = (
    accessToken: string,
    orderId: number,
    orderGroupId: number,
    rowId: number,
    orderRow: OrderRow,
) => api.put<OrderRow>(`/order/${orderId}/group/${orderGroupId}/row/${rowId}`, accessToken, orderRow);

export const deleteOrderRow = (accessToken: string, orderId: number, orderGroupId: number, rowId: number) =>
    api.delete<OrderRow>(`/order/${orderId}/group/${orderGroupId}/row/${rowId}`, accessToken);

export const copyOrderRow = (accessToken: string, orderId: number, orderGroupId: number, rowId: number) =>
    api.patch<OrderRow>(`/order/${orderId}/group/${orderGroupId}/row/${rowId}/copy`, accessToken);

export const refreshOrderRowPrice = (accessToken: string, orderId: number, orderGroupId: number, rowId: number) =>
    api.patch<PriceWrapper>(`/order/${orderId}/group/${orderGroupId}/row/${rowId}/refreshPrice`, accessToken);

export const setOrderRowPrice = (
    accessToken: string,
    orderId: number,
    orderGroupId: number,
    rowId: number,
    price: number,
) =>
    api.patch<PriceWrapper>(`/order/${orderId}/group/${orderGroupId}/row/${rowId}/price`, accessToken, {
        price,
    });

export const createOrderAccessoryRow = (
    accessToken: string,
    orderId: number,
    orderGroupId: number,
    orderRow?: OrderRow,
) => api.post<OrderRow>(`/order/${orderId}/group/${orderGroupId}/accessoryRow`, accessToken, orderRow);

export const updateOrderAccessoryRow = (
    accessToken: string,
    orderId: number,
    orderGroupId: number,
    rowId: number,
    orderRow: OrderRow,
) => api.put<OrderRow>(`/order/${orderId}/group/${orderGroupId}/accessoryRow/${rowId}`, accessToken, orderRow);

export const deleteOrderAccessoryRow = (accessToken: string, orderId: number, orderGroupId: number, rowId: number) =>
    api.delete<OrderRow>(`/order/${orderId}/group/${orderGroupId}/accessoryRow/${rowId}`, accessToken);

export const copyOrderAccessoryRow = (accessToken: string, orderId: number, orderGroupId: number, rowId: number) =>
    api.patch<OrderRow>(`/order/${orderId}/group/${orderGroupId}/accessoryRow/${rowId}/copy`, accessToken);

export const refreshOrderAccessoryRowPrice = (
    accessToken: string,
    orderId: number,
    orderGroupId: number,
    rowId: number,
) => api.patch<PriceWrapper>(`/order/${orderId}/group/${orderGroupId}/accessoryRow/${rowId}/refreshPrice`, accessToken);

export const setOrderAccessoryRowPrice = (
    accessToken: string,
    orderId: number,
    orderGroupId: number,
    rowId: number,
    price: number,
) =>
    api.patch<PriceWrapper>(`/order/${orderId}/group/${orderGroupId}/accessoryRow/${rowId}/price`, accessToken, {
        price,
    });
