import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

enum ColumnWidth {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
}

export interface FieldOption {
    id: number;
    name: string;
    image: { image: string } | null;
}

export type FieldValue = FieldOption | string | number | null;

export interface Field {
    field: {
        '@type': string;
        allowEmpty?: boolean;
        copyToNewRow: boolean;
        firstDefault?: boolean;
        help: string;
        id: number;
        minNumber?: number;
        maxNumber?: number;
        minLength?: number;
        maxLength?: number;
        name: string;
        width: ColumnWidth;
        options?: FieldOption[];
    };
    defaultValue: string;
    hidden: boolean;
}

export interface CategoryLink {
    id: number;
    name: string;
    image: { image: string };
    showSquare: boolean;
    excelLoad: boolean;
    calculatePrice: boolean;
}

export interface Category {
    id: number;
    name: string;
    image: { image: string };
    fields: Field[];
    accessoryFields: Field[];
    calculatePrice: boolean;
    excelLoad: boolean;
    showSquares: boolean;
    salesCondition: string | null;
}

// State

export interface CategoriesState {
    categoryLinks: CategoryLink[];
    categories: Record<number, Category>;
}

// Action types

export const GET_CATEGORY_LINKS = 'categories:GET_CATEGORY_LINKS';
export const GET_CATEGORY_LINKS_SUCCESS = 'categories:GET_CATEGORY_LINKS_SUCCESS';
export const GET_CATEGORY_LINKS_FAIL = 'categories:GET_CATEGORY_LINKS_FAIL';
export const GET_CATEGORY = 'categories:GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'categories:GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'categories:GET_CATEGORY_FAIL';

// Actions

export interface GetCategoryLinksAction extends Action {
    type: typeof GET_CATEGORY_LINKS;
}

export interface GetCategoryLinksSuccessAction extends Action {
    type: typeof GET_CATEGORY_LINKS_SUCCESS;
    payload: {
        categoryLinks: CategoryLink[];
    };
}

export interface GetCategoryLinksFailsAction extends Action {
    type: typeof GET_CATEGORY_LINKS_FAIL;
    error: AjaxError;
}

export interface GetCategoryAction extends Action {
    type: typeof GET_CATEGORY;
    payload: {
        id: number;
    };
}

export interface GetCategorySuccessAction extends Action {
    type: typeof GET_CATEGORY_SUCCESS;
    payload: {
        category: Category;
    };
}

export interface GetCategoryFailAction extends Action {
    type: typeof GET_CATEGORY_FAIL;
    error: AjaxError;
}

export type CategoriesActionTypes =
    | GetCategoryLinksAction
    | GetCategoryLinksSuccessAction
    | GetCategoryLinksFailsAction
    | GetCategoryAction
    | GetCategorySuccessAction
    | GetCategoryFailAction;
