import React from 'react';

interface Props {
    error?: string;
    show: boolean;
}

const ErrorFlash = ({ error, show }: Props) => <div className={`flash flash-error ${show && 'show'}`}>{error}</div>;

export default ErrorFlash;
