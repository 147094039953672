import FileSaver from 'file-saver';
import _ from 'lodash';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { RootState } from '..';
import { api, parseContentDispositionFilename } from '../../utils/api';
import { downloadOrderPdfFail, downloadOrderPdfSuccess, fetchOldOrdersFail, fetchOldOrdersSuccess } from './actions';
import {
    DOWNLOAD_ORDER_PDF,
    DOWNLOAD_ORDER_PDF_FAIL,
    DownloadOrderPdfAction,
    FETCH_OLD_ORDERS,
    FETCH_OLD_ORDERS_SUCCESS,
    FetchOldOrdersAction,
    OldOrdersActionTypes,
    OldOrdersState,
} from './types';

const initialState: OldOrdersState = {
    orders: [],
    contentRange: 1,
    pageSize: 10,
    loading: false,
    pdfNotFound: [],
};

const oldOrders = (state = initialState, action: OldOrdersActionTypes): OldOrdersState => {
    switch (action.type) {
        case FETCH_OLD_ORDERS:
            return _.assign({}, state, { loading: true });
        case FETCH_OLD_ORDERS_SUCCESS:
            return _.assign({}, state, {
                orders: action.payload.orders,
                contentRange: action.payload.contentRange,
                loading: false,
            });
        case DOWNLOAD_ORDER_PDF_FAIL:
            return {
                ...state,
                pdfNotFound: [...state.pdfNotFound, action.payload.id],
            };
        default:
            return state;
    }
};

export const fetchOldOrdersEpic = (
    action$: Observable<FetchOldOrdersAction>,
    state$: StateObservable<RootState>,
): Observable<OldOrdersActionTypes> =>
    action$.pipe(
        ofType(FETCH_OLD_ORDERS),
        mergeMap((action) =>
            api
                .get('/order', {
                    page: action.payload.page.toString(10),
                    size: state$.value.oldOrders.pageSize.toString(10),
                    sort: 'orderTime,DESC',
                    filter: {
                        ...action.payload.filter,
                        status: ['SENT', 'LEMONSOFT_VALIDATE_FAIL', 'LEMONSOFT_SEND_FAIL'],
                    },
                })
                .pipe(
                    // range=[X,Y]
                    map((res) => fetchOldOrdersSuccess(res.response, res.xhr.getResponseHeader('content-range') || '')),
                    catchError((error) => of(fetchOldOrdersFail(error))),
                ),
        ),
    );

export const downloadOrderPdfEpic = (action$: Observable<DownloadOrderPdfAction>): Observable<OldOrdersActionTypes> =>
    action$.pipe(
        ofType(DOWNLOAD_ORDER_PDF),
        mergeMap((action) =>
            api.blob(`/order/${action.payload.id}/pdf`).pipe(
                map((res) => ({
                    blob: new Blob([res.response], { type: res.xhr.getResponseHeader('Content-Type') || undefined }),
                    filename: parseContentDispositionFilename(res.xhr.getResponseHeader('Content-Disposition')),
                })),
                map(({ blob, filename }) => FileSaver.saveAs(blob, filename)),
                map(() => downloadOrderPdfSuccess()),
                catchError((error) => of(downloadOrderPdfFail(error, action.payload.id))),
            ),
        ),
    );

export default oldOrders;
