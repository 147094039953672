import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import { FixOrderActionTypes, LOAD_FIX_ORDER_SUCCESS } from '../fix-order/types';
import { importSavedOrder } from '../saved-orders/actions';
import { CONTINUE_SAVED_ORDER, IMPORT_SAVED_ORDER, SavedOrdersActionTypes } from '../saved-orders/types';
import { excelProcessed, excelReset, pingExcel, pingExcelFail, submitExcelFail, submitExcelSuccess } from './actions';
import {
    CLEAR_ORDER,
    EXCEL_PROCESSED,
    EXCEL_RESET,
    IMPORT_EXCEL,
    ImportExcelAction,
    OrdersActionTypes,
    OrdersState,
    PING_EXCEL,
    PingExcelAction,
    SUBMIT_EXCEL,
    SUBMIT_EXCEL_FAIL,
    SUBMIT_EXCEL_RESET,
    SUBMIT_EXCEL_SUCCESS,
    SubmitExcelAction,
} from './types';

const initialState: OrdersState = {
    excelProcessingProgress: 0,
    excelProcessingCategory: undefined,
    importingOrder: false,
    showExcelSuccess: false,
    showExcelFail: false,
};

export default (
    state = initialState,
    action: OrdersActionTypes | SavedOrdersActionTypes | FixOrderActionTypes,
): OrdersState => {
    switch (action.type) {
        case CLEAR_ORDER:
            return {
                ...state,
                order: undefined,
            };
        case CONTINUE_SAVED_ORDER:
            return {
                ...state,
                importingOrder: false,
                order: action.payload.order,
            };
        case IMPORT_SAVED_ORDER:
            return {
                ...state,
                importingOrder: true,
            };
        case LOAD_FIX_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload.order,
            };
        case EXCEL_PROCESSED:
            return {
                ...state,
                excelProcessingProgress: 1,
                excelProcessingStatus: true,
            };
        case EXCEL_RESET:
            return {
                ...state,
                showExcelSuccess: false,
                showExcelFail: false,
                excelProcessingCategory: undefined,
                excelProcessingId: undefined,
                excelProcessingProgress: 0,
                excelProcessingStatus: undefined,
            };
        case PING_EXCEL:
            return {
                ...state,
                excelProcessingProgress: action.payload.progress,
            };
        case SUBMIT_EXCEL_SUCCESS:
            return {
                ...state,
                showExcelSuccess: true,
                excelProcessingId: action.payload.id,
                excelProcessingOrderId: action.payload.orderId,
                excelProcessingCategory: action.payload.categoryId,
                excelProcessingStatus: false,
            };
        case SUBMIT_EXCEL_FAIL:
            return {
                ...state,
                showExcelFail: true,
            };
        case SUBMIT_EXCEL_RESET:
            return {
                ...state,
                showExcelSuccess: false,
                showExcelFail: false,
            };
        default:
            return state;
    }
};

// Epics

export const submitExcelEpic = (action$: Observable<SubmitExcelAction>): Observable<OrdersActionTypes> =>
    action$.pipe(
        ofType(SUBMIT_EXCEL),
        mergeMap((action) =>
            api.post('/excel/', { ...action.payload }).pipe(
                mergeMap((res) => [
                    submitExcelSuccess(res.response.id, res.response.orderId, action.payload.categoryId),
                    pingExcel(res.response.id, 0),
                ]),
                catchError((error) => of(submitExcelFail(error))),
            ),
        ),
    );

export const pingExcelEpic = (action$: Observable<PingExcelAction>): Observable<OrdersActionTypes> =>
    action$.pipe(
        ofType(PING_EXCEL),
        delay(0.5 * 1000),
        mergeMap((action) =>
            api.get(`/excel/${action.payload.id}`).pipe(
                map((res) => {
                    if (res.response.done) {
                        return excelProcessed();
                    } else {
                        return pingExcel(action.payload.id, res.response.processed / res.response.total);
                    }
                }),
                catchError((error) => of(pingExcelFail(error))),
            ),
        ),
    );

export const importExcelEpic = (
    action$: Observable<ImportExcelAction>,
): Observable<OrdersActionTypes | SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(IMPORT_EXCEL),
        mergeMap((action) => [importSavedOrder(action.payload.id), excelReset()]),
    );
