import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

export interface User {
    id: string | null;
    customerNumber: string | null;
    name: string | null;
    company: string | null;
    email: string | null;
    role: string | null;
    showDefaultCategories: boolean;
    fixedReference: string | null;
}

// State

export interface UserState {
    user?: User;
}

// Action types

export const INIT_USER = 'user:INIT_USER';
export const INIT_USER_SUCCESS = 'user:INIT_USER_SUCCESS';
export const INIT_USER_FAIL = 'user:INIT_USER_FAIL';

// Actions

export interface InitUserAction extends Action {
    type: typeof INIT_USER;
}

export interface InitUserSuccessAction extends Action {
    type: typeof INIT_USER_SUCCESS;
    payload: {
        user: User;
    };
}

export interface InitUserFailAction extends Action {
    type: typeof INIT_USER_FAIL;
    error: AjaxError;
}

export type UserChangeActionTypes = InitUserAction | InitUserSuccessAction | InitUserFailAction;
