import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '../../assets/images/icons/close.svg';
import { RootState } from '../../redux';
import { excelReset, importExcel } from '../../redux/orders/actions';

const ExcelNotification = () => {
    const dispatch = useDispatch();

    const { excelProcessingOrderId, excelProcessingCategory, excelProcessingProgress, excelProcessingStatus } =
        useSelector((state: RootState) => state.orders);

    const handleImportExcel = () => {
        if (excelProcessingOrderId) {
            dispatch(importExcel(excelProcessingOrderId));
        }
    };

    const handleDismissNotification = () => {
        dispatch(excelReset());
    };

    // if (!excelProcessingStatus) {
    if (excelProcessingCategory === undefined) {
        return <div />;
    }

    return (
        <div id='excel-notification'>
            {/* {!excelProcessed && ( */}
            {!excelProcessingStatus && (
                <span>
                    Ladatun tilauksen vaihe:{' '}
                    <span className='excel-notification__progress'>{`${Math.min(excelProcessingProgress * 100, 99)
                        .toFixed(2)
                        .replace('.', ',')} %`}</span>
                </span>
            )}
            {/* {excelProcessed && ( */}
            {excelProcessingStatus && (
                <span>
                    Tilauksen käsittely on valmis. Voit avata sen{' '}
                    <button className='btn btn-link' onClick={handleImportExcel}>
                        tästä
                    </button>
                    , tai löydät sen myöhemmin tallennetuista tilauksista.
                </span>
            )}
            <button className='excel-notification__close btn btn-icon' onClick={handleDismissNotification}>
                <img src={CloseIcon} alt='sulje' />
            </button>
        </div>
    );
};

export default ExcelNotification;
