import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

export interface Customer {
    id: string;
    name: string;
    company: string;
    excelAllowed: boolean;
    categoryAllowed: boolean;
}

// State

export interface CustomerState {
    customer?: Customer;
}

// Action types

export const INIT_CUSTOMER = 'customer:INIT_CUSTOMER';
export const INIT_CUSTOMER_SUCCESS = 'customer:INIT_CUSTOMER_SUCCESS';
export const INIT_CUSTOMER_FAIL = 'customer:INIT_CUSTOMER_FAIL';

// Actions

export interface InitCustomerAction extends Action {
    type: typeof INIT_CUSTOMER;
}

export interface InitCustomerSuccessAction extends Action {
    type: typeof INIT_CUSTOMER_SUCCESS;
    payload: {
        customer: Customer;
    };
}

export interface InitCustomerFailAction extends Action {
    type: typeof INIT_CUSTOMER_FAIL;
    error: AjaxError;
}

export type CustomerActionTypes = InitCustomerAction | InitCustomerSuccessAction | InitCustomerFailAction;
