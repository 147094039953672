import { useEffect, useRef } from 'react';

import { ITextEditorParam } from '../types';

const ITextEditor = ({ value, onValueChange, maxLength }: ITextEditorParam) => {
    const refInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // focus on the input
        setTimeout(() => {
            if (refInput.current !== null) {
                refInput.current.focus();
            }
        });
    }, []);

    return (
        <div className='ag-cell-edit-wrapper'>
            <div className='ag-cell-editor ag-labeled ag-label-align-left ag-text-field ag-input-field'>
                <div className='ag-wrapper ag-input-wrapper ag-text-field-input-wrapper'>
                    <input
                        ref={refInput}
                        className='ag-input-field-input ag-text-field-input'
                        style={{ height: '40px' }}
                        type='text'
                        aria-label='Input Editor'
                        maxLength={maxLength}
                        // Tertiary needed or there will be a "Changing from uncontrolled to controlled component" -error
                        value={value !== null ? value : ''}
                        onChange={({ target: { value: newValue } }) => onValueChange(newValue)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ITextEditor;
