import { AjaxError } from 'rxjs/ajax';

import {
    Category,
    CategoryLink,
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    GET_CATEGORY_LINKS,
    GET_CATEGORY_LINKS_FAIL,
    GET_CATEGORY_LINKS_SUCCESS,
    GET_CATEGORY_SUCCESS,
    GetCategoryAction,
    GetCategoryFailAction,
    GetCategoryLinksAction,
    GetCategoryLinksFailsAction,
    GetCategoryLinksSuccessAction,
    GetCategorySuccessAction,
} from './types';

export const getCategoryLinks = (): GetCategoryLinksAction => ({
    type: GET_CATEGORY_LINKS,
});

export const getCategoryLinksSuccess = (res: CategoryLink[]): GetCategoryLinksSuccessAction => ({
    type: GET_CATEGORY_LINKS_SUCCESS,
    payload: {
        categoryLinks: res,
    },
});

export const getCategoryLinksFail = (error: AjaxError): GetCategoryLinksFailsAction => ({
    type: GET_CATEGORY_LINKS_FAIL,
    error,
});

export const getCategory = (id: number): GetCategoryAction => ({
    type: GET_CATEGORY,
    payload: {
        id,
    },
});

export const getCategorySuccess = (res: Category): GetCategorySuccessAction => ({
    type: GET_CATEGORY_SUCCESS,
    payload: {
        category: res,
    },
});

export const getCategoryFail = (error: AjaxError): GetCategoryFailAction => ({
    type: GET_CATEGORY_FAIL,
    error,
});
