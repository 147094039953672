import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';

// State

export interface FixOrderState {
    id?: number;
    category?: {
        id: string;
        name: string;
    };
    info?: {
        customer: string;
        time: string;
        errorMessage: string;
        status: string;
    };
}

// Action types

export const LOAD_FIX_ORDER = 'fix-order:LOAD_FIX_ORDER';
export const LOAD_FIX_ORDER_SUCCESS = 'fix-order:LOAD_FIX_ORDER_SUCCESS';
export const LOAD_FIX_ORDER_FAIL = 'fix-order:LOAD_FIX_ORDER_FAIL';
export const END_FIX_ORDER = 'fix-order:END_FIX_ORDER';

// Actions

export interface LoadFixOrderAction extends Action {
    type: typeof LOAD_FIX_ORDER;
    payload: {
        id: string;
    };
}

export interface LoadFixOrderSuccessAction extends Action {
    type: typeof LOAD_FIX_ORDER_SUCCESS;
    payload: {
        order: Order;
    };
}

export interface LoadFixOrderFailAction extends Action {
    type: typeof LOAD_FIX_ORDER_FAIL;
    error: AjaxError;
}

export interface EndFixOrderAction extends Action {
    type: typeof END_FIX_ORDER;
}

export type FixOrderActionTypes =
    | LoadFixOrderAction
    | LoadFixOrderSuccessAction
    | LoadFixOrderFailAction
    | EndFixOrderAction;
