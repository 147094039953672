import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';

export interface OrdersState {
    excelProcessingId?: string;
    excelProcessingOrderId?: number;
    excelProcessingCategory?: number;
    excelProcessingProgress: number;
    excelProcessingStatus?: boolean;
    importingOrder: boolean;
    order?: Order;
    showExcelSuccess: boolean;
    showExcelFail: boolean;
}

export const CLEAR_ORDER = 'fix-order:CLEAR_ORDER';
export const EXCEL_PROCESSED = 'orders:EXCEL_PROCESSED';
export const EXCEL_RESET = 'orders:EXCEL_RESET';
export const IMPORT_EXCEL = 'orders:IMPORT_EXCEL';
export const PING_EXCEL = 'orders:PING_EXCEL';
export const PING_EXCEL_FAIL = 'orders:PING_EXCEL_FAIL';
export const SUBMIT_EXCEL = 'orders:SUBMIT_EXCEL';
export const SUBMIT_EXCEL_SUCCESS = 'orders:SUBMIT_EXCEL_SUCCESS';
export const SUBMIT_EXCEL_FAIL = 'orders:SUBMIT_EXCEL_FAIL';
export const SUBMIT_EXCEL_RESET = 'orders:SUBMIT_EXCEL_RESET';

export interface ClearOrderAction extends Action {
    type: typeof CLEAR_ORDER;
}

export interface ExcelProcessedAction extends Action {
    type: typeof EXCEL_PROCESSED;
}

export interface ExcelResetAction extends Action {
    type: typeof EXCEL_RESET;
}

export interface ImportExcelAction extends Action {
    type: typeof IMPORT_EXCEL;
    payload: {
        id: number;
    };
}

export interface PingExcelAction extends Action {
    type: typeof PING_EXCEL;
    payload: {
        id: number;
        progress: number;
    };
}

export interface PingExcelFailAction extends Action {
    type: typeof PING_EXCEL_FAIL;
    error: AjaxError;
}

export interface SubmitExcelAction extends Action {
    type: typeof SUBMIT_EXCEL;
    payload: {
        categoryId: number;
        customerId: string | null;
        name: string;
        data: string | ArrayBuffer | null | undefined;
    };
}

export interface SubmitExcelSuccessAction extends Action {
    type: typeof SUBMIT_EXCEL_SUCCESS;
    payload: {
        id: string;
        orderId: number;
        categoryId: number;
    };
}

export interface SubmitExcelFailAction extends Action {
    type: typeof SUBMIT_EXCEL_FAIL;
    error: AjaxError;
}

export interface SubmitExcelResetAction extends Action {
    type: typeof SUBMIT_EXCEL_RESET;
}

export type OrdersActionTypes =
    | ClearOrderAction
    | ExcelProcessedAction
    | ExcelResetAction
    | ImportExcelAction
    | PingExcelAction
    | PingExcelFailAction
    | SubmitExcelAction
    | SubmitExcelSuccessAction
    | SubmitExcelFailAction
    | SubmitExcelResetAction;
