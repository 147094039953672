import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import { getCategoryFail, getCategoryLinksFail, getCategoryLinksSuccess, getCategorySuccess } from './actions';
import {
    CategoriesActionTypes,
    CategoriesState,
    GET_CATEGORY,
    GET_CATEGORY_LINKS,
    GET_CATEGORY_LINKS_SUCCESS,
    GET_CATEGORY_SUCCESS,
    GetCategoryAction,
    GetCategoryLinksAction,
} from './types';

const initialState: CategoriesState = {
    categoryLinks: [],
    categories: {},
};

export default (state = initialState, action: CategoriesActionTypes): CategoriesState => {
    switch (action.type) {
        case GET_CATEGORY_LINKS_SUCCESS:
            return _.assign({}, state, {
                categoryLinks: action.payload.categoryLinks,
            });
        case GET_CATEGORY_SUCCESS:
            return _.assign({}, state, {
                categories: {
                    ...state.categories,
                    [action.payload.category.id]: action.payload.category,
                },
            });
        default:
            return state;
    }
};

// EPICS

export const getCategoryLinksEpic = (action$: Observable<GetCategoryLinksAction>): Observable<CategoriesActionTypes> =>
    action$.pipe(
        ofType(GET_CATEGORY_LINKS),
        mergeMap(() =>
            // IMPORTANT: Set as 50 to override default of 20. If number of categories keeps increasing might need
            // some other solution
            api.get('/category', { size: '50' }).pipe(
                map((res) => getCategoryLinksSuccess(res.response)),
                catchError((error) => of(getCategoryLinksFail(error))),
            ),
        ),
    );

export const getCategoryEpic = (action$: Observable<GetCategoryAction>): Observable<CategoriesActionTypes> =>
    action$.pipe(
        ofType(GET_CATEGORY),
        mergeMap((action) =>
            api.get(`/category/${action.payload.id}`).pipe(
                map((res) => getCategorySuccess(res.response)),
                catchError((error) => of(getCategoryFail(error))),
            ),
        ),
    );
