import { createUserManager } from 'redux-oidc';

// Authentication
const userManagerConfig = {
    authority: window.config.issuer,
    client_id: window.config.clientId,
    redirect_uri: `${window.location.origin}/login`,
    response_type: 'code',
    scope: 'openid',
    automaticSilentRenew: true,
    post_logout_redirect_uri: window.config.logoutRedirectUri,
};

const userManager = createUserManager(userManagerConfig);
export default userManager;
