import { Customer } from '../../redux/customer/types';
import api from '../../utils/apiWithoutRedux';

export const getCustomers = (accessToken: string, categoryId?: number, q?: string) =>
    api.get<Customer[]>(`/customer`, accessToken, {
        filter: JSON.stringify({
            ...(q ? { q } : {}),
            ...(categoryId ? { categoryId: categoryId + '' } : {}),
        }),
    });

export const getCustomer = (accessToken: string, id: string): Promise<Customer> =>
    api.get<Customer[]>(`/customer`, accessToken, { filter: JSON.stringify({ id: [id] }) }).then((customers) => {
        if (customers.length === 0) throw new Error('No customers found');
        return customers[0];
    });
