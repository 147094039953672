import { AjaxError } from 'rxjs/ajax';

import {
    INIT_CONFIG,
    INIT_CONFIG_FAIL,
    INIT_CONFIG_SUCCESS,
    InitConfigAction,
    InitConfigFailAction,
    InitConfigSuccessAction,
} from './types';

export const initConfig = (): InitConfigAction => ({
    type: INIT_CONFIG,
});

export const initConfigSuccess = (res: Record<string, string>): InitConfigSuccessAction => ({
    type: INIT_CONFIG_SUCCESS,
    payload: {
        res,
    },
});

export const initConfigFail = (error: AjaxError): InitConfigFailAction => ({
    type: INIT_CONFIG_FAIL,
    error,
});
