import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';
import {
    END_FIX_ORDER,
    EndFixOrderAction,
    LOAD_FIX_ORDER,
    LOAD_FIX_ORDER_FAIL,
    LOAD_FIX_ORDER_SUCCESS,
    LoadFixOrderAction,
    LoadFixOrderFailAction,
    LoadFixOrderSuccessAction,
} from './types';

export const loadFixOrder = (id: string): LoadFixOrderAction => ({
    type: LOAD_FIX_ORDER,
    payload: {
        id,
    },
});

export const loadFixOrderSuccess = (res: Order): LoadFixOrderSuccessAction => ({
    type: LOAD_FIX_ORDER_SUCCESS,
    payload: {
        order: res,
    },
});

export const loadFixOrderFail = (error: AjaxError): LoadFixOrderFailAction => ({
    type: LOAD_FIX_ORDER_FAIL,
    error,
});

export const endFixOrder = (): EndFixOrderAction => ({
    type: END_FIX_ORDER,
});
