import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CloseIcon from '../../../assets/images/icons/close.svg';
import { RootState } from '../../../redux';
import { importExcel } from '../../../redux/orders/actions';

interface Props {
    handleClose: () => void;
    open: boolean;
}

const ExcelSuccessModal = ({ handleClose, open }: Props) => {
    const dispatch = useDispatch();

    const { excelProcessingOrderId, excelProcessingStatus } = useSelector((state: RootState) => state.orders);

    const handleImportExcel = () => {
        handleClose();
        excelProcessingOrderId && dispatch(importExcel(excelProcessingOrderId));
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={handleClose}
            style={{
                content: {
                    paddingTop: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'column',
                },
            }}
            ariaHideApp={false}
        >
            <button className='modal__close btn btn-icon' onClick={handleClose}>
                <img src={CloseIcon} alt='sulje' />
            </button>
            <div className='modal__title-wrap'>
                <div className='modal__title modal__title--center'>
                    {!excelProcessingStatus ? (
                        <>
                            <p>
                                Excelin lataaminen onnistui, mutta sen käsittelyyn menee jonkin aikaa. Tilaus tulee
                                näkyviin{' '}
                                <Link className='text-link' to='/tallennetut-tilaukset'>
                                    tallennettuihin tilauksiin
                                </Link>{' '}
                                kun se on valmis ja siitä tulee ilmoitus näytölle.
                            </p>
                            <p>
                                Huomioithan, että nähdäksesi tilauksen tallennetuissa, on sinun valittava sama asiakas
                                kuin valitsit excelin lataamiseen.
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                Excelin käsittely on valmis. Voit avata sen{' '}
                                <button className='btn-link' onClick={handleImportExcel}>
                                    tästä
                                </button>
                                , tai löydät sen myöhemminkin tallennetuista tilauksista.
                            </p>
                            <p>
                                Huomioithan, että nähdäksesi tilauksen tallennetuissa, on sinun valittava sama asiakas
                                kuin valitsit excelin lataamiseen.
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className='modal__actions btn-row stack-element'>
                <button className='btn btn-secondary' onClick={handleClose}>
                    Palaa etusivulle
                </button>
            </div>
        </Modal>
    );
};

export default ExcelSuccessModal;
