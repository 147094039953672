import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import CustomerPicker from '../../components/customer-picker';
import OrderList from '../../components/order-list';
import { getCategoryLinks } from '../../redux/categories/actions';
import { fetchOldOrders } from '../../redux/old-orders/actions';
import ListItemAction from './list-item-action';

const selectStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: 0,
        backgroundColor: 'white',
        borderColor: state.isFocused ? '#5d5d5d' : 'darkgrey',
        outline: 'none',
        boxShadow: 'none',
        minHeight: 'initial',
        height: '32px',
    }),
};

const OldOrders = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState({
        searchText: '',
        customer: null,
        searchId: '',
        searchCategory: { value: null },
        searchDateStart: '',
        searchDateStop: '',
    });

    const { orders, contentRange, pageSize, loading } = useSelector((state) => state.oldOrders);
    const categories = useSelector((state) => state.categories.categoryLinks);
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getCategoryLinks()); // This is called to make sure a list of categories exists
        dispatch(fetchOldOrders(0, {}));
        document.title = 'Tilausportaali - Tehdyt tilaukset';
    }, []);

    const handleFetchOldOrders = (page) => {
        const filter = {
            q: search.searchText || undefined,
            customer: search.customer?.id || undefined,
            orderId: search.searchId || undefined,
            categoryId: search.searchCategory.value || undefined,
            startTime: search.searchDateStart || undefined,
            endTime: search.searchDateStop || undefined,
        };
        dispatch(fetchOldOrders(page, filter));
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
    };

    const categoryOptions = Object.values(categories).map((c) => ({ label: c.name, value: c.id }));
    categoryOptions.unshift({ label: '-', value: null });

    return (
        <div className='small-wrapper'>
            <link href='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css' />
            <h1>Tehdyt tilaukset</h1>

            {/* SEARCH */}

            <form className='order-info__search' onSubmit={handleSearchSubmit}>
                {user?.role === 'ADMIN' && (
                    <CustomerPicker
                        setSelectedCustomer={(customer) => setSearch({ ...search, customer })}
                        selectedCustomer={search.customer}
                        type='simple'
                        selectStyles={selectStyles}
                    />
                )}
                <div className='order-info__search-input-wrapper'>
                    <label htmlFor='old-orders-search-text'>Viite/Merkki</label>
                    <input
                        type='text'
                        id='old-orders-search-text'
                        className='order-info__search-input order-info__search-text'
                        value={search.searchText}
                        onChange={(e) => setSearch({ ...search, searchText: e.target.value })}
                    />
                </div>
                <div className='order-info__search-input-wrapper'>
                    <label htmlFor='old-orders-search-id'>Tilausnumero</label>
                    <input
                        type='text'
                        id='old-orders-search-id'
                        className='order-info__search-input order-info__search-text'
                        value={search.searchId}
                        onChange={(e) => setSearch({ ...search, searchId: e.target.value })}
                    />
                </div>
                <div className='order-info__search-input-wrapper'>
                    <label htmlFor='old-orders-search-category'>Kategoria</label>
                    <Select
                        id='old-orders-search-category'
                        className='order-info__search-input order-info__search-category'
                        options={categoryOptions}
                        value={search.searchCategory}
                        onChange={(selectedOption) => setSearch({ ...search, searchCategory: selectedOption })}
                        styles={selectStyles}
                        classNamePrefix='react-select'
                        placeholder='-'
                    />
                </div>
                <div className='order-info__search-input-wrapper'>
                    <label htmlFor='old-orders-search-date-start'>Aloituspäivämäärä</label>
                    <input
                        type='date'
                        id='old-orders-search-date-start'
                        className='order-info__search-input order-info__search-date-start'
                        value={search.searchDateStart}
                        onChange={(e) => setSearch({ ...search, searchDateStart: e.target.value })}
                    />
                </div>
                <div className='order-info__search-input-wrapper'>
                    <label htmlFor='old-orders-search-date-stop'>Lopetuspäivämäärä</label>
                    <input
                        type='date'
                        id='old-orders-search-date-stop'
                        className='order-info__search-input order-info__search-date-stop'
                        value={search.searchDateStop}
                        onChange={(e) => setSearch({ ...search, searchDateStop: e.target.value })}
                    />
                </div>
                <div className='order-info__search-input-wrapper'>
                    <label style={{ color: 'rgba(0, 0, 0, 0)' }}>.</label>
                    <button
                        className='order-info__search-input btn btn-primary'
                        onClick={() => handleFetchOldOrders(0)}
                        disabled={loading}
                    >
                        Etsi
                    </button>
                </div>
            </form>

            {/* RESULTS */}

            <div className='old-orders-list'>
                <OrderList
                    list={orders}
                    contentRange={contentRange}
                    pageSize={pageSize}
                    loading={loading}
                    fetchOrders={handleFetchOldOrders}
                    listItemAction={ListItemAction}
                />
            </div>
        </div>
    );
};

export default OldOrders;
