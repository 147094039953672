const defaultHeaders = {
    'Content-type': 'application/json',
};

const handleResponse = async (res: Response) => {
    const isJson = res.headers.get('Content-Type')?.startsWith('application/json');
    if (!res.ok) throw new Error(JSON.stringify(await (isJson ? res.json() : res.text())));
    return isJson ? res.json() : res.text();
};

const handleBlobResponse = async (res: Response) => {
    if (!res.ok)
        throw new Error(
            JSON.stringify(
                await (res.headers.get('Content-Type')?.startsWith('application/json') ? res.json() : res.text()),
            ),
        );
    return res.blob();
};

const generateApiWithUrlRoot = (urlRoot = '') => ({
    get: <T>(
        url: string,
        accessToken: string,
        queryParameters?: string | string[][] | Record<string, string> | URLSearchParams,
        headers: Record<string, string> = {},
    ): Promise<T> =>
        fetch(`${urlRoot}${url}${queryParameters ? '?' + new URLSearchParams(queryParameters) : ''}`, {
            headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}`, ...headers },
        }).then(handleResponse),

    post: <T>(url: string, accessToken: string, body: object = {}, headers: Record<string, string> = {}): Promise<T> =>
        fetch(`${urlRoot}${url}`, {
            method: 'POST',
            headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}`, ...headers },
            body: JSON.stringify(body),
        }).then(handleResponse),

    put: <T>(url: string, accessToken: string, body: object = {}, headers: Record<string, string> = {}): Promise<T> =>
        fetch(`${urlRoot}${url}`, {
            method: 'PUT',
            headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}`, ...headers },
            body: JSON.stringify(body),
        }).then(handleResponse),

    delete: <T>(url: string, accessToken: string, headers: Record<string, string> = {}): Promise<T> =>
        fetch(`${urlRoot}${url}`, {
            method: 'DELETE',
            headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}`, ...headers },
        }).then(handleResponse),

    patch: <T>(url: string, accessToken: string, body?: object, headers: Record<string, string> = {}): Promise<T> =>
        fetch(`${urlRoot}${url}`, {
            method: 'PATCH',
            headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}`, ...headers },
            ...(body ? { body: JSON.stringify(body) } : {}),
        }).then(handleResponse),
    blob: (url: string, accessToken: string) =>
        fetch(`${urlRoot}${url}`, {
            headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        }).then(handleBlobResponse),
});

export default generateApiWithUrlRoot(window.config.apiUrl);
