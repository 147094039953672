import { AjaxError } from 'rxjs/ajax';

import {
    INIT_USER,
    INIT_USER_FAIL,
    INIT_USER_SUCCESS,
    InitUserAction,
    InitUserFailAction,
    InitUserSuccessAction,
    User,
} from './types';

export const initUser = (): InitUserAction => ({
    type: INIT_USER,
});

export const initUserSuccess = (user: User): InitUserSuccessAction => ({
    type: INIT_USER_SUCCESS,
    payload: {
        user,
    },
});

export const initUserFail = (error: AjaxError): InitUserFailAction => ({
    type: INIT_USER_FAIL,
    error,
});
