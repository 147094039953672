import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux';
import { downloadAnnouncementFile, initAnnouncements, readAnnouncements } from '../../redux/announcements/actions';
import { AnnouncementFile } from '../../redux/announcements/types';

const Announcements = () => {
    const dispatch = useDispatch();

    const announcements = useSelector((state: RootState) => state.announcements.announcements);

    useEffect(() => {
        dispatch(initAnnouncements());
        dispatch(readAnnouncements());
        document.title = 'Tilausportaali - Tiedotteet';
    }, []);

    const handleClick = (announcementId: number, file: AnnouncementFile) => {
        if (!announcementId) return;
        dispatch(downloadAnnouncementFile(announcementId, file.id));
    };

    return (
        <div className='small-wrapper'>
            <h1>Ajankohtaiset tiedotteet</h1>
            <ul className='announcements__list'>
                {announcements.map((announcement) => (
                    <li className='announcements__list-item' key={announcement.releaseDate}>
                        <div className='announcements__list-item-title'>
                            [{new Date(announcement.releaseDate).toLocaleDateString()}]<div>{announcement.title}</div>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: announcement.message }} />
                        <div>
                            {announcement.files.map((file) => (
                                <div key={file.id}>
                                    <button className='btn btn-link' onClick={() => handleClick(announcement.id, file)}>
                                        Avaa liite: {file.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default Announcements;
