import { useNavigate } from 'react-router-dom';

import { CallbackComponent } from 'redux-oidc';

import userManager from '../../utils/userManager';

const Login = () => {
    const navigate = useNavigate();

    const successCallback = (user) => {
        navigate((user.state && user.state.path) || '/');
    };

    const errorCallback = (error) => {
        // TODO
        console.error('login Error Callback', error);
    };

    return (
        <CallbackComponent userManager={userManager} successCallback={successCallback} errorCallback={errorCallback}>
            <div>
                <div>Ohjataan sivu automaattisesti...</div>
                <a href={`/?reset=` + new Date().getTime()}>Klikkaa tästä, jos sivu ei ohjaudu automaattisesti</a>
            </div>
        </CallbackComponent>
    );
};

export default Login;
