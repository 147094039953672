import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';
import { parseContentRange } from '../../utils/api';
import {
    DOWNLOAD_ORDER_PDF,
    DOWNLOAD_ORDER_PDF_FAIL,
    DOWNLOAD_ORDER_PDF_SUCCESS,
    DownloadOrderPdfAction,
    DownloadOrderPdfFailAction,
    DownloadOrderPdfSuccessAction,
    FETCH_OLD_ORDERS,
    FETCH_OLD_ORDERS_FAIL,
    FETCH_OLD_ORDERS_SUCCESS,
    FetchOldOrdersAction,
    FetchOldOrdersFailAction,
    FetchOldOrdersSuccessAction,
} from './types';

export const fetchOldOrders = (page: number, filter: Record<string, string>): FetchOldOrdersAction => ({
    type: FETCH_OLD_ORDERS,
    payload: {
        page,
        filter,
    },
});

export const fetchOldOrdersSuccess = (orders: Order[], cr: string): FetchOldOrdersSuccessAction => ({
    type: FETCH_OLD_ORDERS_SUCCESS,
    payload: {
        orders,
        contentRange: parseContentRange(cr),
    },
});

export const fetchOldOrdersFail = (error: AjaxError): FetchOldOrdersFailAction => ({
    type: FETCH_OLD_ORDERS_FAIL,
    error,
});

export const downloadOrderPdf = (id: number): DownloadOrderPdfAction => ({
    type: DOWNLOAD_ORDER_PDF,
    payload: {
        id,
    },
});

export const downloadOrderPdfSuccess = (): DownloadOrderPdfSuccessAction => ({
    type: DOWNLOAD_ORDER_PDF_SUCCESS,
});

export const downloadOrderPdfFail = (error: AjaxError, id: number): DownloadOrderPdfFailAction => ({
    type: DOWNLOAD_ORDER_PDF_FAIL,
    payload: {
        error,
        id,
    },
});
