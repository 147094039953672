import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { customerportalApi } from '../../utils/customerportalApi';
import {
    downloadAnnouncementFileFail,
    downloadAnnouncementFileSuccess,
    initAnnouncementsFail,
    initAnnouncementsSuccess,
    readAnnouncementsSuccess,
} from './actions';
import {
    AnnouncementsActionTypes,
    AnnouncementsState,
    DOWNLOAD_ANNOUNCEMENT_FILE,
    DownloadAnnouncementFileAction,
    INIT_ANNOUNCEMENTS,
    INIT_ANNOUNCEMENTS_SUCCESS,
    InitAnnouncementsAction,
    READ_ANNOUNCEMENTS,
    READ_ANNOUNCEMENTS_SUCCESS,
    ReadAnnouncementsAction,
} from './types';

const initialState: AnnouncementsState = {
    announcements: [],
    announcementsRead: window.localStorage.getItem('announcements_read'),
    unreadAnnouncements: false,
};

const announcements = (state = initialState, action: AnnouncementsActionTypes): AnnouncementsState => {
    switch (action.type) {
        case INIT_ANNOUNCEMENTS:
            return _.assign({}, state, { unreadAnnouncements: !state.announcementsRead });
        case INIT_ANNOUNCEMENTS_SUCCESS: {
            let unreadAnnouncements = false;
            if (!state.announcementsRead) {
                unreadAnnouncements = true;
            } else {
                action.payload.announcements.forEach((a) => {
                    if (new Date(Number(state.announcementsRead)) < new Date(a.releaseDate)) {
                        unreadAnnouncements = true;
                        return;
                    }
                });
            }

            return _.assign({}, state, { announcements: action.payload.announcements, unreadAnnouncements });
        }
        case READ_ANNOUNCEMENTS_SUCCESS:
            return _.assign({}, state, { announcementsRead: Date.now(), unreadAnnouncements: false });
        default:
            return state;
    }
};

export const downloadAnnouncementFileEpic = (
    action$: Observable<DownloadAnnouncementFileAction>,
): Observable<AnnouncementsActionTypes> =>
    action$.pipe(
        ofType(DOWNLOAD_ANNOUNCEMENT_FILE),
        mergeMap((action) =>
            customerportalApi.blob(`/news/${action.payload.id}/file/${action.payload.mediaId}`).pipe(
                map((res) => ({
                    blob: new Blob([res.response], { type: res.xhr.getResponseHeader('Content-Type') || undefined }),
                })),
                map(({ blob }) => window.open(URL.createObjectURL(blob), '_blank')),
                map(() => downloadAnnouncementFileSuccess()),
                catchError((error) =>
                    of(downloadAnnouncementFileFail(error, action.payload.id, action.payload.mediaId)),
                ),
            ),
        ),
    );

export const initAnnouncementsEpic = (
    action$: Observable<InitAnnouncementsAction>,
): Observable<AnnouncementsActionTypes> =>
    action$.pipe(
        ofType(INIT_ANNOUNCEMENTS),
        mergeMap(() =>
            customerportalApi
                .get('/news', {
                    filter: {
                        published: true,
                        after: new Date(new Date().getTime() - 31536000000).toISOString(),
                    },
                    page: 0,
                    size: 8,
                    sort: 'releaseDate,DESC',
                })
                .pipe(
                    map((res) => initAnnouncementsSuccess(res.response)),
                    catchError((error) => of(initAnnouncementsFail(error))),
                ),
        ),
    );

export const readAnnouncementsEpic = (
    action$: Observable<ReadAnnouncementsAction>,
): Observable<AnnouncementsActionTypes> =>
    action$.pipe(
        ofType(READ_ANNOUNCEMENTS),
        tap(() => window.localStorage.setItem('announcements_read', String(Date.now() || ''))),
        map(() => readAnnouncementsSuccess()),
    );

export default announcements;
