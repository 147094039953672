import React from 'react';
import Modal from 'react-modal';

import CloseIcon from '../../../assets/images/icons/close.svg';
import LoadingIcon from '../../loading-icon';

interface Props {
    error: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    loading: boolean;
    open: boolean;
    orderIsEmpty: boolean;
    orderIsInvalid: boolean;
    orderPricesOrProductCodesAreInvalid: boolean;
    salesConditionAccepted: boolean;
}

const SubmitConfirmModal = ({
    error,
    handleClose,
    handleSubmit,
    loading,
    open,
    orderIsEmpty,
    orderIsInvalid,
    orderPricesOrProductCodesAreInvalid,
    salesConditionAccepted,
}: Props) => (
    <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
            content: {
                paddingTop: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'column',
            },
        }}
        ariaHideApp={false}
    >
        <button className='modal__close btn btn-icon' onClick={handleClose}>
            <img src={CloseIcon} alt='sulje' />
        </button>
        {loading ? (
            <div className='modal__title-wrap'>
                <div className='modal__title modal__title--center'>
                    <LoadingIcon />
                </div>
            </div>
        ) : (
            <>
                <div className='modal__title-wrap'>
                    <div className='modal__title modal__title--center'>
                        {orderIsEmpty ? (
                            <span>
                                <div className='error-text'>HUOM!</div>
                                Et voi lähettää tyhjää tilausta!
                            </span>
                        ) : orderIsInvalid ? (
                            <span>
                                <div className='error-text'>HUOM!</div>
                                Tilaus ei ole kelvollinen! Korjaa punaisella merkityt kentät
                            </span>
                        ) : orderPricesOrProductCodesAreInvalid ? (
                            <span>
                                <div className='error-text'>HUOM!</div>
                                Virheellinen tuotekoodi tai hinta. Tarkista rivit, joilla tuotekoodi tai hinta on
                                merkitty virheelliseksi. Tämä johtuu todennäköisesti siitä, että annetuille mitoille
                                pystytty laskemaan hintaa tai monivalinnat eivät ole sopivia keskenään.
                            </span>
                        ) : error ? (
                            <span>
                                <div className='error-text'>HUOM!</div>
                                Tilauksen joitakin rivejä ei ole saatu käsiteltyä oikein. Tarkista rivit, joissa on
                                oikeassa reunassa merkki virheestä. Voit kokeilla muuttaa rivin jotain arvoa ja katsoa
                                katoaako virhe ja tämän jälkeen vaihtaa arvon takaisin.
                            </span>
                        ) : !salesConditionAccepted ? (
                            <span>
                                <div className='error-text'>HUOM!</div>
                                Sinun täytyy hyväksyä yleiset myynti- ja toimitusehdot ennen kuin voit lähettää
                                tilauksen. Hyväksy ehdot sulkemalla tämä ilmoitus ja laittamalla ruksi Lähetä tilaus
                                -painikkeen yläpuolella olevaan laatikkoon.
                            </span>
                        ) : (
                            <span>Oletko varma, että haluat lähettää tilauksen?</span>
                        )}
                    </div>
                </div>
                <div className='modal__actions btn-row stack-element'>
                    <button className='btn btn-secondary' onClick={handleClose}>
                        Peruuta
                    </button>
                    <button
                        className='btn btn-primary'
                        onClick={handleSubmit}
                        disabled={
                            orderIsEmpty ||
                            orderIsInvalid ||
                            orderPricesOrProductCodesAreInvalid ||
                            error ||
                            !salesConditionAccepted
                        }
                    >
                        Lähetä tilaus
                    </button>
                </div>
            </>
        )}
    </Modal>
);

export default SubmitConfirmModal;
