import { OrderRow } from '../lib/order/types';
import { Field, FieldValue } from '../redux/categories/types';

export const validateCell = (value: FieldValue, field: Field, strictMode: boolean): boolean => {
    // Ignore hidden fields
    if (field.hidden) {
        return true;
    }
    // Empty case
    if (value === null || value === undefined || value === '') {
        // allowEmpty is for selects
        return !(strictMode && !field.field.allowEmpty);
    }

    if (field.field['@type'] === 'Text') {
        if (field.field.minLength != null && (value as string).length < field.field.minLength) {
            return false;
        } else if (field.field.maxLength != null && (value as string).length > field.field.maxLength) {
            return false;
        }
    } else if (field.field['@type'] === 'Number') {
        if (field.field.minNumber != null && (value as number) < field.field.minNumber) {
            return false;
        } else if (field.field.maxNumber != null && (value as number) > field.field.maxNumber) {
            return false;
        } else if ((value as number) % 1 !== 0) {
            // The input should not allow decimals, but this is here just in case
            return false;
        }
    }
    return true;
};

export const validateOrderFields = (orderRow: OrderRow, fields: Field[]): boolean =>
    orderRow.fields
        .filter(({ deprecated }) => !deprecated) // Ignore deprecated fields
        .map(({ fieldId, value }) => ({
            field: fields.find((field) => field.field.id === fieldId),
            value,
        }))
        .map(({ field, value }) => (field ? validateCell(value, field, true) : false))
        .every((x) => x);
