import _ from 'lodash';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { RootState } from '..';
import { api } from '../../utils/api';
import history from '../../utils/history';
import {
    checkSavedOrdersFail,
    checkSavedOrdersSuccess,
    continueSavedOrder,
    continueSavedOrderHelper,
    deleteSavedOrderFail,
    deleteSavedOrderSuccess,
    fetchSavedOrders,
    fetchSavedOrdersFail,
    fetchSavedOrdersSuccess,
    importSavedOrderFail,
} from './actions';
import {
    CHECK_SAVED_ORDERS,
    CHECK_SAVED_ORDERS_SUCCESS,
    CONTINUE_SAVED_ORDER_HELPER,
    CheckSavedOrdersAction,
    ContinueSavedOrderHelperAction,
    DELETE_SAVED_ORDER,
    DELETE_SAVED_ORDER_CLEAR,
    DELETE_SAVED_ORDER_FAIL,
    DELETE_SAVED_ORDER_SUCCESS,
    DeleteSavedOrderAction,
    FETCH_SAVED_ORDERS,
    FETCH_SAVED_ORDERS_FAIL,
    FETCH_SAVED_ORDERS_SUCCESS,
    FetchSavedOrdersAction,
    IMPORT_SAVED_ORDER,
    ImportSavedOrderAction,
    PROC_DELETE_SAVED_ORDER,
    SavedOrdersActionTypes,
    SavedOrdersState,
} from './types';

const initialState: SavedOrdersState = {
    savedOrders: false,
    orders: [],
    pageSize: 10,
    contentRange: 1,
    loading: false,
    showSaveSuccess: false,
    showSaveFail: false,
    deleteId: null,
    deletePage: null,
    deleteFail: false,
    deleting: false,
    filtersBeforeDeleting: null,
};

const savedOrders = (state = initialState, action: SavedOrdersActionTypes): SavedOrdersState => {
    switch (action.type) {
        case CHECK_SAVED_ORDERS_SUCCESS:
            return _.assign({}, state, { savedOrders: action.payload.savedOrders });
        case FETCH_SAVED_ORDERS:
            return _.assign({}, state, { loading: true });
        case FETCH_SAVED_ORDERS_SUCCESS:
            return _.assign({}, state, {
                orders: action.payload.orders,
                contentRange: action.payload.contentRange,
                loading: false,
            });
        case FETCH_SAVED_ORDERS_FAIL:
            return _.assign({}, state, { loading: false, deleteFail: true });
        case PROC_DELETE_SAVED_ORDER:
            return _.assign({}, state, {
                deleteId: action.payload.id,
                deletePage: action.payload.page,
                deleteFail: false,
                filtersBeforeDeleting: action.payload.filters,
            });
        case DELETE_SAVED_ORDER:
            return _.assign({}, state, {
                deleting: true,
            });
        case DELETE_SAVED_ORDER_CLEAR:
        case DELETE_SAVED_ORDER_SUCCESS:
            return _.assign({}, state, {
                deleteId: null,
                deletePage: null,
                deleting: false,
                deleteFail: false,
                filtersBeforeDeleting: null,
            });
        case DELETE_SAVED_ORDER_FAIL:
            return _.assign({}, state, {
                deleting: false,
                deleteFail: true,
            });
        default:
            return state;
    }
};

export const checkSavedOrdersEpic = (action$: Observable<CheckSavedOrdersAction>): Observable<SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(CHECK_SAVED_ORDERS),
        mergeMap(() =>
            api
                .get(`/order`, {
                    filter: {
                        status: ['SAVED'],
                    },
                    page: '0',
                    size: '1',
                })
                .pipe(
                    map((res) => checkSavedOrdersSuccess(res.response.length)),
                    catchError((error) => of(checkSavedOrdersFail(error))),
                ),
        ),
    );

export const fetchSavedOrdersEpic = (
    action$: Observable<FetchSavedOrdersAction>,
    state$: StateObservable<RootState>,
): Observable<SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(FETCH_SAVED_ORDERS),
        mergeMap((action) =>
            api
                .get('/order', {
                    filter: {
                        ...action.payload.filter,
                        status: ['SAVED'],
                    },
                    page: action.payload.page.toString(10),
                    size: state$.value.savedOrders.pageSize.toString(10),
                    sort: 'orderTime,DESC',
                })
                .pipe(
                    map((res) => fetchSavedOrdersSuccess(res.response, res.xhr.getResponseHeader('content-range'))),
                    catchError((error) => of(fetchSavedOrdersFail(error))),
                ),
        ),
    );

export const importSavedOrderEpic = (action$: Observable<ImportSavedOrderAction>): Observable<SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(IMPORT_SAVED_ORDER),
        mergeMap((action) =>
            api.get(`/order/${action.payload.id}`).pipe(
                map((res) => res.response),
                catchError((error) => of(importSavedOrderFail(error))),
            ),
        ),
        map((order) => continueSavedOrderHelper(order)),
    );

export const continueSavedOrderEpic = (
    action$: Observable<ContinueSavedOrderHelperAction>,
): Observable<SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(CONTINUE_SAVED_ORDER_HELPER),
        tap((action) => history.push(`/kategoria/${action.payload.order.category.id}`)),
        map((action) => continueSavedOrder(action.payload.order)),
    );

export const deleteSavedOrderEpic = (
    action$: Observable<DeleteSavedOrderAction>,
    state$: StateObservable<RootState>,
): Observable<SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(DELETE_SAVED_ORDER),
        mergeMap(() =>
            api.delete(`/order/${state$.value.savedOrders.deleteId}`).pipe(
                mergeMap(() => [
                    fetchSavedOrders(
                        state$.value.savedOrders.deletePage || 0,
                        state$.value.savedOrders.filtersBeforeDeleting ?? {},
                    ),
                    deleteSavedOrderSuccess(),
                ]),
                catchError((error) => of(deleteSavedOrderFail(error))),
            ),
        ),
    );

export default savedOrders;
