import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Order } from '../../lib/order/types';
import { importSavedOrder, procDeleteSavedOrder } from '../../redux/saved-orders/actions';

interface props {
    order: Order;
    page: number;
    filters: Record<string, string>;
}

const ListItemAction: FC<props> = ({ order, page, filters }) => {
    const dispatch = useDispatch();

    const handleImportSavedOrder = () => {
        dispatch(importSavedOrder(order.id));
    };

    const handleProcDeleteSavedOrder = () => {
        dispatch(procDeleteSavedOrder(order.id, page - 1, filters));
    };

    return (
        <div className='order-info__actions'>
            <button className='btn btn-link order-info__action' onClick={handleImportSavedOrder}>
                Jatka
            </button>
            <button className='btn btn-link btn-link--warning order-info__action' onClick={handleProcDeleteSavedOrder}>
                Poista
            </button>
        </div>
    );
};

export default ListItemAction;
