import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TawkTo from 'tawkto-react';

import CategoryLink from '../../components/category-link';
import { RootState } from '../../redux';
import { getCategoryLinks } from '../../redux/categories/actions';
import { checkSavedOrders } from '../../redux/saved-orders/actions';
import { Tawk } from '../../types';

const Home: FC = () => {
    const dispatch = useDispatch();

    const categoryLinks = useSelector((state: RootState) => state.categories.categoryLinks);
    const config = useSelector((state: RootState) => state.config.config);
    const user = useSelector((state: RootState) => state.oidc.user);

    const [, setTawk] = useState<Tawk>();

    useEffect(() => {
        dispatch(checkSavedOrders());
        dispatch(getCategoryLinks());
        document.title = 'Tilausportaali - Etusivu';
    }, []);

    useEffect(() => {
        if (config === undefined) return;
        const regexpNames = /http(.*)\/chat\/(?<propertyId>\w+)\/(?<tawkId>\w+)/gm;
        const match = regexpNames.exec(config.tawkToLink);
        if (match?.groups?.propertyId && match?.groups?.tawkId) {
            const tawk: Tawk = new TawkTo(match?.groups?.propertyId, match?.groups?.tawkId);
            tawk.onLoad(() => {
                tawk.setAttributes({
                    name: `${user?.profile.company} - ${user?.profile.name}`,
                    email: user?.profile.email,
                    hash: config.tawkToHash,
                });
            });
            setTawk(tawk);
        }
    }, [config]);

    return (
        <div className='small-wrapper stack-element'>
            <ul className='category-links'>
                {categoryLinks.map((category) => (
                    <CategoryLink
                        key={category.id}
                        id={category.id}
                        name={category.name}
                        img={category.image ? category.image.image : ''}
                    />
                ))}
            </ul>
        </div>
    );
};

export default Home;
