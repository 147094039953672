import { AjaxError } from 'rxjs/ajax';

import {
    Customer,
    INIT_CUSTOMER,
    INIT_CUSTOMER_FAIL,
    INIT_CUSTOMER_SUCCESS,
    InitCustomerAction,
    InitCustomerFailAction,
    InitCustomerSuccessAction,
} from './types';

export const initCustomer = (): InitCustomerAction => ({
    type: INIT_CUSTOMER,
});

export const initCustomerSuccess = (customer: Customer): InitCustomerSuccessAction => ({
    type: INIT_CUSTOMER_SUCCESS,
    payload: {
        customer,
    },
});

export const initCustomerFail = (error: AjaxError): InitCustomerFailAction => ({
    type: INIT_CUSTOMER_FAIL,
    error,
});
