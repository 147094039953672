import { AjaxError } from 'rxjs/ajax';

import {
    CLEAR_ORDER,
    ClearOrderAction,
    EXCEL_PROCESSED,
    EXCEL_RESET,
    ExcelProcessedAction,
    ExcelResetAction,
    IMPORT_EXCEL,
    ImportExcelAction,
    PING_EXCEL,
    PING_EXCEL_FAIL,
    PingExcelAction,
    PingExcelFailAction,
    SUBMIT_EXCEL,
    SUBMIT_EXCEL_FAIL,
    SUBMIT_EXCEL_RESET,
    SUBMIT_EXCEL_SUCCESS,
    SubmitExcelAction,
    SubmitExcelFailAction,
    SubmitExcelResetAction,
    SubmitExcelSuccessAction,
} from './types';

export const clearOrder = (): ClearOrderAction => ({
    type: CLEAR_ORDER,
});

export const excelProcessed = (): ExcelProcessedAction => ({
    type: EXCEL_PROCESSED,
});

export const excelReset = (): ExcelResetAction => ({
    type: EXCEL_RESET,
});

export const pingExcel = (id: number, progress: number): PingExcelAction => ({
    type: PING_EXCEL,
    payload: {
        id,
        progress,
    },
});

export const pingExcelFail = (error: AjaxError): PingExcelFailAction => ({
    type: PING_EXCEL_FAIL,
    error,
});

export const submitExcel = (
    categoryId: number,
    name: string,
    file: string | ArrayBuffer | null | undefined,
    customerId: string | null,
): SubmitExcelAction => ({
    type: SUBMIT_EXCEL,
    payload: {
        categoryId,
        customerId,
        name,
        data: file,
    },
});

export const submitExcelSuccess = (id: string, orderId: number, categoryId: number): SubmitExcelSuccessAction => ({
    type: SUBMIT_EXCEL_SUCCESS,
    payload: {
        id,
        orderId,
        categoryId,
    },
});

export const submitExcelFail = (error: AjaxError): SubmitExcelFailAction => ({
    type: SUBMIT_EXCEL_FAIL,
    error,
});

export const submitExcelReset = (): SubmitExcelResetAction => ({
    type: SUBMIT_EXCEL_RESET,
});

export const importExcel = (id: number): ImportExcelAction => ({
    type: IMPORT_EXCEL,
    payload: {
        id,
    },
});
