import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import userManager from '../../utils/userManager';
import Login from '../../views/login';

const AuthWrapper = ({ children, onSuccess }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/login') return;
        userManager.getUser().then((user) => {
            if (!user || user.expired) {
                userManager.signinRedirect({
                    data: {
                        path: location.pathname,
                    },
                });
            } else if (!isAuthenticated) {
                onSuccess();
                setIsAuthenticated(true);
            }
        });
    });
    if (location.pathname === '/login') return <Login />;

    return isAuthenticated ? children : <div>Loading...</div>;
};

export default AuthWrapper;
