import { createRoot } from 'react-dom/client';

import App from './App';
import CustomRouter from './utils/customRouter';
import history from './utils/history';

declare global {
    interface Window {
        // __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
        config: {
            issuer: string;
            clientId: string;
            apiUrl: string;
            logoutRedirectUri: string;
            customerportalUrl: string;
            customerportalApiUrl: string;
            orderportalUrl: string;
        };
    }
}

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);
root.render(
    <CustomRouter history={history}>
        <App />
    </CustomRouter>,
);
