import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import { CategoriesActionTypes } from '../categories/types';
import { continueSavedOrder } from '../saved-orders/actions';
import { SavedOrdersActionTypes } from '../saved-orders/types';
import { loadFixOrderFail, loadFixOrderSuccess } from './actions';
import {
    END_FIX_ORDER,
    FixOrderActionTypes,
    FixOrderState,
    LOAD_FIX_ORDER,
    LOAD_FIX_ORDER_SUCCESS,
    LoadFixOrderAction,
    LoadFixOrderSuccessAction,
} from './types';

const initialState: FixOrderState = {
    id: undefined,
    category: undefined,
    info: undefined,
};

const fixOrder = (state = initialState, action: FixOrderActionTypes): FixOrderState => {
    switch (action.type) {
        case LOAD_FIX_ORDER:
            return _.assign({}, state, {
                id: action.payload.id,
            });
        case LOAD_FIX_ORDER_SUCCESS:
            return _.assign({}, state, {
                category: action.payload.order.category,
                info: {
                    customer: action.payload.order.customer,
                    time: action.payload.order.orderTime,
                    status: action.payload.order.status,
                },
            });
        case END_FIX_ORDER:
            return initialState;
        default:
            return state;
    }
};

// EPICS

export const loadFixOrderEpic = (
    action$: Observable<LoadFixOrderAction>,
): Observable<FixOrderActionTypes | CategoriesActionTypes> =>
    action$.pipe(
        ofType(LOAD_FIX_ORDER),
        mergeMap((action) =>
            api.get(`/order/${action.payload.id}`).pipe(
                map((res) => loadFixOrderSuccess(res.response)),
                catchError((error) => of(loadFixOrderFail(error))),
            ),
        ),
    );

export const continueFixOrderEpic = (
    action$: Observable<LoadFixOrderSuccessAction>,
): Observable<SavedOrdersActionTypes> =>
    action$.pipe(
        ofType(LOAD_FIX_ORDER_SUCCESS),
        map((action) => continueSavedOrder(action.payload.order)),
    );

export default fixOrder;
