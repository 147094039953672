import { useDispatch, useSelector } from 'react-redux';

import { downloadOrderPdf } from '../../redux/old-orders/actions';

const ListItemAction = (props) => {
    const { order } = props;
    const dispatch = useDispatch();
    const { pdfNotFound } = useSelector((state) => state.oldOrders);

    const handleClick = () => {
        dispatch(downloadOrderPdf(order.id));
    };

    return pdfNotFound.indexOf(order.id) === -1 ? (
        <div className='order-info__actions'>
            <button className='btn btn-link' onClick={handleClick}>
                Lataa PDF
            </button>
        </div>
    ) : (
        'PDF ei ladattavissa'
    );
};

export default ListItemAction;
