import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';
import { parseContentRange } from '../../utils/api';
import {
    CHECK_SAVED_ORDERS,
    CHECK_SAVED_ORDERS_FAIL,
    CHECK_SAVED_ORDERS_SUCCESS,
    CONTINUE_SAVED_ORDER,
    CONTINUE_SAVED_ORDER_HELPER,
    CheckSavedOrderSuccessAction,
    CheckSavedOrdersAction,
    CheckSavedOrdersFailAction,
    ContinueSavedOrderAction,
    ContinueSavedOrderHelperAction,
    DELETE_SAVED_ORDER,
    DELETE_SAVED_ORDER_CLEAR,
    DELETE_SAVED_ORDER_FAIL,
    DELETE_SAVED_ORDER_SUCCESS,
    DeleteSavedOrderAction,
    DeleteSavedOrderClearAction,
    DeleteSavedOrderFailAction,
    DeleteSavedOrderSuccessAction,
    FETCH_SAVED_ORDERS,
    FETCH_SAVED_ORDERS_FAIL,
    FETCH_SAVED_ORDERS_SUCCESS,
    FetchSavedOrdersAction,
    FetchSavedOrdersFailAction,
    FetchSavedOrdersSuccessAction,
    IMPORT_SAVED_ORDER,
    IMPORT_SAVED_ORDER_FAIL,
    ImportSavedOrderAction,
    ImportSavedOrderFailAction,
    PROC_DELETE_SAVED_ORDER,
    ProcDeleteSavedOrderAction,
} from './types';

export const checkSavedOrders = (): CheckSavedOrdersAction => ({
    type: CHECK_SAVED_ORDERS,
});
export const checkSavedOrdersSuccess = (reslen: number): CheckSavedOrderSuccessAction => ({
    type: CHECK_SAVED_ORDERS_SUCCESS,
    payload: {
        savedOrders: reslen > 0,
    },
});

export const checkSavedOrdersFail = (error: AjaxError): CheckSavedOrdersFailAction => ({
    type: CHECK_SAVED_ORDERS_FAIL,
    error,
});

export const fetchSavedOrders = (page: number, filter: Record<string, string>): FetchSavedOrdersAction => ({
    type: FETCH_SAVED_ORDERS,
    payload: {
        page,
        filter,
    },
});

export const fetchSavedOrdersSuccess = (orders: Order[], cr: string | null): FetchSavedOrdersSuccessAction => ({
    type: FETCH_SAVED_ORDERS_SUCCESS,
    payload: {
        orders,
        contentRange: parseContentRange(cr),
    },
});

export const fetchSavedOrdersFail = (error: AjaxError): FetchSavedOrdersFailAction => ({
    type: FETCH_SAVED_ORDERS_FAIL,
    error,
});

export const importSavedOrder = (id: number): ImportSavedOrderAction => ({
    type: IMPORT_SAVED_ORDER,
    payload: {
        id,
    },
});

export const importSavedOrderFail = (error: AjaxError): ImportSavedOrderFailAction => ({
    type: IMPORT_SAVED_ORDER_FAIL,
    error,
});

export const procDeleteSavedOrder = (
    id: number,
    page: number,
    filters: Record<string, string>,
): ProcDeleteSavedOrderAction => ({
    type: PROC_DELETE_SAVED_ORDER,
    payload: {
        id,
        page,
        filters,
    },
});

export const deleteSavedOrder = (): DeleteSavedOrderAction => ({
    type: DELETE_SAVED_ORDER,
});

export const deleteSavedOrderSuccess = (): DeleteSavedOrderSuccessAction => ({
    type: DELETE_SAVED_ORDER_SUCCESS,
});

export const deleteSavedOrderFail = (error: AjaxError): DeleteSavedOrderFailAction => ({
    type: DELETE_SAVED_ORDER_FAIL,
    error,
});

export const deleteSavedOrderClear = (): DeleteSavedOrderClearAction => ({
    type: DELETE_SAVED_ORDER_CLEAR,
});

export const continueSavedOrder = (order: Order): ContinueSavedOrderAction => ({
    type: CONTINUE_SAVED_ORDER,
    payload: {
        order,
    },
});

export const continueSavedOrderHelper = (order: Order): ContinueSavedOrderHelperAction => ({
    type: CONTINUE_SAVED_ORDER_HELPER,
    payload: {
        order,
    },
});
