import { KeyboardEvent } from 'react';
import { useEffect, useRef } from 'react';

import { CustomCellEditorProps } from 'ag-grid-react';

const INumberEditor = ({ value, onValueChange }: CustomCellEditorProps) => {
    const refInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // focus on the input
        setTimeout(() => {
            if (refInput.current !== null) {
                refInput.current.focus();
            }
        });
    }, []);

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') e.preventDefault();
    };

    return (
        <div className='ag-cell-edit-wrapper'>
            <div className='ag-cell-editor ag-labeled ag-label-align-left ag-text-field ag-input-field'>
                <div className='ag-wrapper ag-input-wrapper ag-text-field-input-wrapper'>
                    <input
                        ref={refInput}
                        className='ag-input-field-input ag-text-field-input'
                        style={{ height: '40px' }}
                        type='number'
                        aria-label='Input Editor'
                        // Tertiary needed or there will be a "Changing from uncontrolled to controlled component" -error
                        value={value !== null ? value : ''}
                        // onChange={(event) => setValue(event.target.value)}
                        onChange={({ target: { value: newValue } }) => onValueChange(newValue)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </div>
    );
};

export default INumberEditor;
