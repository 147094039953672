import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import CloseIcon from '../../assets/images/icons/close.svg';
import DownIcon from '../../assets/images/icons/down.svg';
import { copyOrderAccessoryRow, copyOrderRow, deleteOrderAccessoryRow, deleteOrderRow } from '../../lib/order';
import { OrderRow } from '../../lib/order/types';
import { RootState } from '../../redux';
import LoadingIcon from '../loading-icon';

interface Props {
    addRow: (orderRow: OrderRow) => void;
    deleteRow: (rowId: number) => void;
    groupId: number;
    hideError: () => void;
    orderId: number;
    rowId: number;
    showError: (_: string) => void;
    type: 'NORMAL' | 'ACCESSORY';
}

const RowAction = ({ addRow, deleteRow, groupId, hideError, orderId, rowId, showError, type }: Props) => {
    const [loadingCopy, setLoadingCopy] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const oidcUser = useSelector((state: RootState) => state.oidc.user);

    const handleCopyRow = useCallback(
        (rowId: number) => {
            hideError();
            setLoadingCopy(true);
            (type === 'NORMAL' ? copyOrderRow : copyOrderAccessoryRow)(
                oidcUser?.access_token ?? '',
                orderId,
                groupId,
                rowId,
            )
                .then(addRow)
                .catch((err) => {
                    console.error(err);
                    showError(
                        'Rivin kopioiminen epäonnistui. Voit kokeilla päivittää sivun ja ladata tilauksen ' +
                            'tallennetuista tilauksta. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                    );
                })
                .finally(() => setLoadingCopy(false));
        },
        [oidcUser?.access_token, type, orderId, groupId],
    );

    const handleDeleteRow = useCallback(
        (rowId: number) => {
            hideError();
            setLoadingDelete(true);
            (type === 'NORMAL' ? deleteOrderRow : deleteOrderAccessoryRow)(
                oidcUser?.access_token ?? '',
                orderId,
                groupId,
                rowId,
            )
                .then(() => deleteRow(rowId))
                .catch((err) => {
                    console.error(err);
                    showError(
                        'Rivin poistaminen epäonnistui. Voit kokeilla päivittää sivun ja ladata tilauksen ' +
                            'tallennetuista tilauksta. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                    );
                })
                .finally(() => setLoadingDelete(false));
        },
        [oidcUser?.access_token, type, orderId, groupId],
    );

    return (
        <div className='sub-order__row-actions'>
            {/* COPY ROW */}
            <div className='tooltip'>
                <button
                    className='sub-order__row-action copy'
                    onClick={() => handleCopyRow(rowId)}
                    disabled={loadingCopy}
                >
                    {loadingCopy ? <LoadingIcon /> : <img src={DownIcon} alt='kopio rivi' />}
                </button>
                <div className='tooltip__wrapper'>
                    <div className='tooltip__text'>Kopioi rivi</div>
                </div>
            </div>
            {/* DELETE ROW */}
            <div className='tooltip'>
                <button
                    className='sub-order__row-action'
                    onClick={() => handleDeleteRow(rowId)}
                    disabled={loadingDelete}
                >
                    {loadingDelete ? <LoadingIcon /> : <img src={CloseIcon} alt='poista rivi' />}
                </button>
                <div className='tooltip__wrapper'>
                    <div className='tooltip__text'>Poista rivi</div>
                </div>
            </div>
        </div>
    );
};

export default RowAction;
